(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityStatementUploadController', AffordabilityStatementUploadController);
	
	function AffordabilityStatementUploadController(_, $state, $auth, $stateParams, $log, BankStatementStatementFactory, ToastService){
		var site_id = null;
		var vm = this;
		var token = $auth.getToken();
		
		vm.data = {};			
			
		vm.dropzone = {
			'prev': {
				title: moment().subtract(1, 'month').format('MMMM YYYY'),
				icon: 'icon-upload-statement',
				config: {
					maxFiles: 1,
					maxFilesize: 5,
					acceptedMimeTypes: 'application/pdf',					
					url: "/api/finlayer/bank_statement/statement/upload",
					headers: {"Authorization" : "Bearer " + token},
					addRemoveLinks: true,		
					dictRemoveFile: 'Replace File',
					init: function() { this.on("maxfilesexceeded", function(file) { this.removeAllFiles(); this.addFile(file); }); },		
					dictDefaultMessage : 'Drop files here or click this box to upload.',			
					dictInvalidFileType: 'Only bank statements in PDF format are allowed'																
				},
				element: null,
				uploaded: false,
				asset_id: null
			}	
		};
		
		vm.dzUploadSuccess = function(file, response, e){		
			var input = {
				'asset_id': response.data._id,
				'bank_id': $stateParams.bank_id
			};
			
			BankStatementStatementFactory.postParse(input)
				.then(function(){
					$state.go('private.app.dashboard.modal.affordability.accounts', {source: 'upload'});
				}, function(err){
						if(err.data.status_code == 403){
							$state.go('private.app.dashboard.modal.affordability.statement_password', input);
						}else{
							if(err.data.status_code != 412){
								ToastService.error('We could not process the bank statement you uploaded. Please try to upload another.'); 								
							}
						}
				});
		}
		
		vm.dzError = function(file){
			$(file.previewElement).parent().find('.btn-download').hide();
		}
				
	}
})();