(function() {
	"use strict";

	angular.module('app.controllers').controller('PropertyPriceController', PropertyPriceController);
	
	function PropertyPriceController(_, $state, API, user, PropertyInterestFactory, IntercomFactory, property_interest, certificates){
		var vm = this;
		vm.submit = submit;
		
		vm.property = property_interest;
		vm.certificate = _.last(certificates);
		
		if(_.isEmpty(vm.property)){
			vm.property = {};
		}

		if(!vm.property.hasOwnProperty('qualification_amount')){
			vm.property.qualification_amount = vm.certificate.ooba_certificate.certificateData.certificateAmount;
		}
		
		if(!vm.property.hasOwnProperty('max_price')){
			vm.property.max_price = vm.certificate.ooba_certificate.certificateData.certificateAmount;
		}
		
		if(!vm.property.hasOwnProperty('start_price')){
			vm.property.start_price = vm.certificate.ooba_certificate.certificateData.certificateAmountOption2;
		}				
		
		function submit(){			
			var data = vm.property;
			PropertyInterestFactory.add(data)
				.then(function(){	
						IntercomFactory.trackEvent('PropertyInterest_Confirmed_Price');	
						$state.go('private.app.dashboard.modal.property.location');
				});
		}
		
		IntercomFactory.trackEvent('PropertyInterest_Started');	
		
	}
})();