(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyAssistanceController', VerifyAssistanceController);
	
	function VerifyAssistanceController(_, API, $state, IntercomFactory, $window, $timeout, $stateParams){
		var vm = this;		
		
		vm.submit = function(){
			$state.go('logout');
		}
	}
	
})();
