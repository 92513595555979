(function() {
	"use strict";

	angular.module('app.controllers').controller('PropertyCurrentController', PropertyCurrentController);
	
	function PropertyCurrentController(_, $state, API, user, PropertyInterestFactory, IntercomFactory, property_interest){
		var vm = this;
		vm.submit = submit;
		vm.autocompleteOptions = {
			componentRestrictions: { country: 'za' },
      types: ['geocode']
		};		
		
		vm.data = property_interest;
		
		function submit(){			
			var data = {current: vm.data.current};
			PropertyInterestFactory.add(data, true)
				.then(function(){	
						$state.go('private.app.dashboard.modal.property.success');
				}, function(){
					$state.go('private.app.dashboard.modal.property.failed');
				});
		}
		
	}
})();