(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyFailedAuthenticationController', VerifyFailedAuthenticationController);
	
	function VerifyFailedAuthenticationController(_, API, $state, user, IntercomFactory){
		var vm = this;
		
		vm.submit = function(){
			$state.go('private.app.dashboard.modal.fica.upload');
		}
		IntercomFactory.trackEvent('Security_Questions_Failed_TooManyAttempts');
	}
	
})();
