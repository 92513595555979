(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityWidgetSummaryController', AffordabilityWidgetSummaryController);
	
	function AffordabilityWidgetSummaryController(affordability){
		var vm = this;
		vm.affordability = affordability;
	}	
})();
