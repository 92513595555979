(function() {
	"use strict";

	angular.module('finlayer').factory('CreditCrunchSnapshotFactory', function(API) {
		var factory = {};
		var prefix = 'finlayer/credit_crunch/snapshot';
		
		factory.get = function(){
			return API.one(prefix).get();
		}
		return factory;
	});
})();
