(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityReviewIncomeController', AffordabilityReviewIncomeController);
	
	function AffordabilityReviewIncomeController(_, API, $scope, $state, categories, userProfile, IntercomFactory, UserFactory, $locale){
		var vm = this;
		var user = userProfile;
		vm.total = 0;
		
		//hack
		$locale.NUMBER_FORMATS.CURRENCY_SYM = 'R';
		
		vm.userCategories = {};
		vm.categories = categories;		
		
		_.each(categories, function(category, i){
			if(category.total){				
				vm.userCategories[category._id] = category.total;				
			}
		});		
		
		if(!_.isEmpty(user.profile)){
			if(!_.isEmpty(user.profile.affordability_categories)){
				if(!_.isEmpty(user.profile.affordability_categories.I)){
					var cats = user.profile.affordability_categories.I;
				
					_.each(cats, function(total, category_id){
						vm.userCategories[category_id] = total;
					});
				}
			}
		}
		
		vm.submit = function(){
			var data = {};
			_.each(vm.userCategories, function(total, cat){
				if(isNaN(total)){
					total = 0;
				}
				
				data[cat] = total;
			});

			UserFactory.postProfile({"affordability_categories": {"I": data}})
				.then(function(){			
					IntercomFactory.trackEvent('Affordability_Confirmed_Categories_Income');				
					$state.go('private.app.dashboard.modal.affordability.review_deductions', {}, {reload: true});
				});

		}
		
		vm.calcTotal = function(){
			var total = 0;
			_.each(vm.userCategories, function(amount, cid){
				if(isNaN(amount)){
					amount = 0;
				}
				total += amount;
			});	
			
			return total;	
		}
		
		$scope.$watch('vm.userCategories', function(newVal, oldVal){
		    vm.total = vm.calcTotal();
		}, true);
	}
})();