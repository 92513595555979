(function() {
	"use strict";

	angular.module('app.controllers').controller('PrivateController', PrivateController);
	
	function PrivateController(_, API, $rootScope, $state, $auth, $timeout, IntercomFactory, $localStorage, UserFactory, ToastService, brand, user, assets, affordability, snapshot, property_interest, certificate_count, concierge_certificate_count){
		var vm = this;
		vm.widgets = {
			fica: {status: false},
			profile: {status: false},
			verify: {status: false},
			affordability: {status: false},
			property: {status: false},
			certificate: {status: false}
		};
		

		
		vm.closeModal = function(){
			$state.go("private.app.dashboard", {}, {reload: true});
		}
		
		if(!_.isEmpty(affordability)){
			if(affordability.income > 0){
				vm.widgets.affordability.status = true;
			}
		}	
			
		if(!_.isEmpty(snapshot)){
			if(snapshot.score >= 0){
				vm.widgets.verify.status = true;
			}	
		}
		
		if(!_.isEmpty(user.profile)){
			if(!_.isEmpty(user.profile.contact)){
				vm.widgets.profile.status = true;
			}
		}
		
		if(concierge_certificate_count > 0){
			vm.widgets.property.status = true;
		}		
				
		if(!_.isEmpty(assets)){
			var required_docs = ['id', 'residence'];
			var available_docs = [];
				
			_.each(required_docs, function(doc_name, i){
				_.each(assets, function(asset, j){
					if(asset.description == doc_name){
						available_docs.push(doc_name);
					}
				});
			});

			if(available_docs.length == required_docs.length){
				vm.widgets.fica.status = true;
			}
		}			
		
		if(vm.widgets.verify.status && vm.widgets.affordability.status){
			vm.widgets.certificate.status = true;
		}
		
		if ($auth.isAuthenticated()){
		  IntercomFactory.boot();  		     
	 	}
	 	
	 	if(!_.isEmpty(snapshot)){
		 	if(snapshot.qualifies == false){
				IntercomFactory.trackEvent('Credit_Report_Qualification_Failed');
			 	$state.go('private.app.dashboard.modal.verify.failed_qualification');
		 	}
	 	}
	 	
	 	vm.openCertificate = function(){
		 	if(vm.widgets.certificate.status == true){
		 		$state.go('private.app.dashboard.modal.certificate.index');
		 	}else{
			 	ToastService.error('Please make sure that all steps are completed before you attempt to generate a Certificate'); 
		 	}
	 	}
		
	}
})();