//==============================================
// Intercom Factory
//==============================================

(function() {
	"use strict";
 
	angular.module('app.services').factory('IntercomFactory', function($log, $intercom, $localStorage, UserFactory) {
		var factory = {};
		var User = null;
		
		factory.init = function(){
			var brand = $localStorage.brand;	
			
			if(brand.hasOwnProperty('config')){
				if(brand.config.hasOwnProperty('intercom')){	
					$intercom.boot({app_id: brand.config.intercom.app_id});					
				}
			}			
		}
		
		factory.update = function(user){
			//$intercom.update(user);
		}
		
		factory.boot = function(){
			/*$log.info('Intercom Booting');
			var user = getUser();
			$intercom.boot(user);			
			factory.update(user);*/
		}
		
		factory.trackEvent = function(eventName, data){
			/*$log.info('Triggered Event: '+eventName);
			$intercom.trackEvent(eventName, data);*/
		}		
		
		factory.logout = function(){
			//$intercom.shutdown();
		}		
		
		function getUser(){
			var user = $localStorage.user;
			var brand = $localStorage.brand;						
			
			var intercomUser = {
				  user_id: user._id,
				  user_hash: user.hash,
				  email: user.email,
				  name: user.first_name + ' ' + user.surname,
				  updated_at: moment(user.updated_at).unix(),
				  created_at: moment(user.created_at).unix(),
					client: brand.name,
					app_id: brand.config.intercom.app_id
			  };

			  if(! _.isEmpty(user.photo)){
				  intercomUser.avatar = {
					   "type":"avatar",
						 "image_url": user.photo
				  }
			  }
			  			  console.log(intercomUser);
			  return intercomUser;
		}
		
		return factory;
	});
})();
