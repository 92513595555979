(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityAccountsController', AffordabilityAccountsController);
	
	function AffordabilityAccountsController(_, API, $state, $log, TransActAccountFactory, IntercomFactory, $stateParams, accounts){
		var vm = this;
		vm.accounts = accounts;
		vm.source = $stateParams.source;
		
		vm.bankAccounts = _.groupBy(accounts, function(b) {					
		    return b.site.title;
		});
	
		vm.submit = function(){
			var proceed = false;
			_.each(vm.data['Accounts'], function(status, account){
	    		if(status){
	    			proceed = true;
	    		}
	    	});
	    	
	    	if(proceed == false){
		    	return;
	    	}
	    	
	    	TransActAccountFactory.postEnable(vm.data)
	    		.then(function(accounts){
		    		IntercomFactory.trackEvent('Affordability_Confirmed_Accounts');	
						$state.go('private.app.dashboard.modal.affordability.confirm_income');
	    		}, function(error){
	    			$state.go('private.app.dashboard.modal.affordability.progress');
	    		});
		}
	}
})();