(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyThinFileController', VerifyThinFileController);
	
	function VerifyThinFileController(_, API, $state, user, IntercomFactory){
		var vm = this;
		
		vm.submit = function(){
			$state.go('logout');
		}
		IntercomFactory.trackEvent('ThinFile');	
	}
	
})();
