(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityOptionsController', AffordabilityOptionsController);
	
	function AffordabilityOptionsController(_, API, $state, $log, EventFactory){
		var vm = this;		
		angular.element('.modal-dialog').addClass('w-900');
		
		vm.goto = function(option){
			switch(option){
				case 1:
					EventFactory.trigger('AffordabilityOptionYodleeStarted');
					$state.go('private.app.dashboard.modal.affordability.disclaimer');					
					break;
					
				case 2:
					EventFactory.trigger('AffordabilityOptionBankStatementUploadStarted');
					$state.go('private.app.dashboard.modal.affordability.statement_type');
					break;
					
				case 3:
					EventFactory.trigger('AffordabilityOptionManualStarted');
					$state.go('private.app.dashboard.modal.affordability.review_income'); 
					break;															
			}
		}
	}
})();