(function(){
    "use strict";

    angular.module('app.controllers').controller('HeaderController', HeaderController);

    function HeaderController(user, affordability, snapshot, $state){
        var vm = this;
        vm.user = user;
        vm.snapshot = snapshot;
        vm.affordability = affordability;
        
        vm.openCertificate = function(){
	        if(!_.isEmpty(affordability) && !_.isEmpty(snapshot) && !_.isEmpty(user.mobile)){
						$state.go('private.app.dashboard.modal.certificate.index');
						return;
					}	
        }
    }

})();
