//==============================================
// BOOTSTRAP GROWL
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('ToastService', function() {
		var gs = {};
		gs.error = function(message) {
			$.notify({
				// options
				message : message
			}, {
				// settings
				type : 'danger',
				z_index: 2000
			});
			/*
			 * $.growl({ message: message },{ type: 'error', allow_dismiss:
			 * false, label: 'Cancel', className: 'btn-xs btn-inverse',
			 * placement: { from: 'top', align: 'right' }, delay: 2500, animate: {
			 * enter: 'animated bounceIn', exit: 'animated bounceOut' }, offset: {
			 * x: 20, y: 85 } });
			 */
		}
		
		gs.success = function(message) {
			$.notify({
				// options
				message : message
			}, {
				// settings
				type : 'success',
				z_index: 2000
			});
			/*
			 * $.growl({ message: message },{ type: 'error', allow_dismiss:
			 * false, label: 'Cancel', className: 'btn-xs btn-inverse',
			 * placement: { from: 'top', align: 'right' }, delay: 2500, animate: {
			 * enter: 'animated bounceIn', exit: 'animated bounceOut' }, offset: {
			 * x: 20, y: 85 } });
			 */
		}

		return gs;
	});
})();