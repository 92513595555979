//==============================================
// Address Service
//==============================================

(function() {
	"use strict";

	angular.module('app.services').factory('AddressFactory', function(API) {
		var factory = {};
		
		factory.add = function(data){
			return API.all('address/add').post({Address: data});
		}
		
		return factory;
	});
})();
