//==============================================
// Province Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('CookieFactory', function($q) {
		var factory = {};
		
		
		factory.getAll = function(){
			var cookies = document.cookie.split(/; */);
			var data = {};
	    for (var i = 0; i < cookies.length; i ++){
	
	      // if the cookie has the requested name, add its value to the list
	      var details = cookies[i].split('=');
	      var name = encodeURIComponent(details[0]);
	      var val = decodeURIComponent(details[1].replace(/\+/g, '%20'));
	      
	      data[name] = val;	
	    }
	    
	    return data;
		}
		
		return factory;
	});
})();