(function() {
	"use strict";

	angular.module('app.controllers').controller('CertificateClaimController', CertificateClaimController);
	
	function CertificateClaimController(_, API, $state, $stateParams, CertificateFactory, IntercomFactory){
		var vm = this;
		vm.data = {};
		vm.submit = submit;
		
		function submit(){			
			var partner_code = vm.data.partner_code;
			CertificateFactory.getClaim(partner_code)
				.then(function(response){	
					IntercomFactory.trackEvent('Dual_Loan_Invite_Accepted');
					$state.go('private.app.dashboard.modal.certificate.index', {}, {reload : true});
				}, function(){
						$state.go('private.app.dashboard.modal.certificate.index', {}, {reload : true});
				});
		}
	}
})();