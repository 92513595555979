(function() {
	"use strict";

	angular.module('app.controllers').controller('ProfilePersonalController', ProfilePersonalController);
	
	function ProfilePersonalController(API, UserFactory, CreditCrunchAuth, $state, $timeout, $log, employerTypes, user, IntercomFactory){
		var vm = this;
		vm.user = user;
		vm.employer = {};
		vm.personal = {};
		
		vm.submit = submit;
		vm.employerTypes = employerTypes;
		
		if(user.profile != null){
			if(user.profile.hasOwnProperty('employer')){
				vm.employer = user.profile.employer;	
				vm.contact = user.profile.contact;
			}
		}
		
		if(vm.user.hasOwnProperty('mobile')){
			vm.mobile = vm.user.mobile;
		}
						
		function submit(){			
			var data = {contact: vm.contact, employer: vm.employer};

			UserFactory.postProfile(data)
				.then(function(){						
						IntercomFactory.trackEvent('Profile_Completed');	
						CreditCrunchAuth.getAuthState()
							.then(function(){
								$state.go('private.app.dashboard', {}, {reload: true});		
							}, function(){
								$state.go('private.app.dashboard.modal.fica.upload');
							});
						
				});
		}
	}
	
})();
