(function() {
	"use strict";

	angular.module('app.controllers').controller('AutologinController', AutologinController);

	function AutologinController($log, $auth, $state, $stateParams, cfpLoadingBar, ToastService, $localStorage, $sessionStorage) {	
		var vm = this;
		var user = {
				token: $stateParams.token
		};
		cfpLoadingBar.start();
		var options = {url:'/api/auth/autologin'};		
		
		$auth.login(user, options)
			.then(function(response){
				$log.info(response);
				$state.go('private.app.dashboard.modal.affordability.start');
			}, loginFail);
		
		function loginFail(response){
			$log.error(response);
			ToastService.error('Error occured'); 
			$state.go('public.login');
		}
		
	}

})();
