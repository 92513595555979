(function() {
	"use strict";
	
	angular.module('app.controllers').controller('OtpLimitController', OtpLimitController);
	
	function OtpLimitController(_, API, $state){
		var vm = this;
		
		vm.submitMe = function(){
			$state.go('logout');
		}
	}
	
})();
