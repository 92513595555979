(function() {
	"use strict";

	angular.module('finlayer').factory('TransActTransactionFactory', function(API) {
		var factory = {};
		var prefix = 'finlayer/trans_act/transaction';
		
		factory.getIncome = function(){
			return API.all(prefix + '/income').getList();
		}
		
		factory.getExpenses = function(){
			return API.all(prefix + '/expenses').getList();
		}
		
		factory.postIncome = function(data){
			return API.all(prefix + '/income').post(data);
		}
		
		factory.postExpenses = function(data){
			return API.all(prefix + '/expenses').post(data);
		}
		
		factory.postCategorize = function(data){
			return API.all(prefix + '/categorize').post(data);
		}		

		return factory;
	});
})();
