(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityRentController', AffordabilityRentController);
	
	function AffordabilityRentController(_, API, $state, UserFactory, IntercomFactory, user){
		var vm = this;
		angular.element('.modal-dialog').removeClass('w-900');
			
		vm.current_property = {};
		vm.current_property.type = '';
		
		if(!_.isEmpty(user.profile)){
			if(!_.isEmpty(user.profile.current_property)){
				vm.current_property = user.profile.current_property;
			}
		}
		
		vm.submit = function(){
			var data = {'current_property': vm.current_property};
			UserFactory.postProfile(data)
				.then(function(){			
					IntercomFactory.trackEvent('Affordability_Confirmed_Rent');				
					$state.go('private.app.dashboard.modal.affordability.confirm_manual_income');
				});

		}
	}
})();