(function() {
	"use strict";

	angular.module('app.controllers').controller('ForgotPasswordEmailController', ForgotPasswordEmailController);

	function ForgotPasswordEmailController($log, $state, AuthFactory, ToastService) {	
		var vm = this;
		
		vm.submit = submit;
		
		function submit(){
			var data = {email: vm.email};
			AuthFactory.emailForgotPassword(data)
				.then(function(){
					ToastService.success('Password Reset email was sent to '+ vm.email);					
				});
		}
		
	}

})();
