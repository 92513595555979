(function(){
	"use strict";

	angular.module('app',
		[
		'app.controllers',
		'app.filters',
		'app.services',
		'app.directives',
		'app.routes',
		'app.config',
		'partialsModule',
		'ngSanitize'
		]); 

	angular.module('app.routes', ['ui.router', 'ui.bootstrap', 'angulartics']);
	angular.module('app.controllers', ['ngIntercom', 'ui.router', 'ngStorage', 'ngAnimate', 'restangular', 'angular-loading-bar', 'satellizer', 'ngMessages', 'localytics.directives', 'google.places', 'underscore', 'angularMoment', 'angulartics.debug', 'angulartics.google.analytics', 'angulartics.facebook.pixel']);
	angular.module('app.filters', []);
	angular.module('app.services', ['finlayer']);
	angular.module('app.directives', ['angularUtils.directives.dirPagination', 'ng-currency', 'ngDropzone', 'ngMask', 'nvd3', 'ngOnload']);
	
	angular.module('app.config', [], function($locationProvider) {
    $locationProvider.html5Mode(true);
  })
  .run(function ($rootScope, $location, $state, $stateParams) {

	  $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){ 		  
		  	if(window.hasOwnProperty('hj')){
		      hj('stateChange', $location.url());			  	
		  	}
	  })
	
	  $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error){ 
		  	if(window.hasOwnProperty('hj')){
		      hj('stateChange', $location.url());			  	
		  	}
	  })
  })
  
  	
})();
