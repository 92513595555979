(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyLeadSubmitFailController', VerifyLeadSubmitFailController);
	
	function VerifyLeadSubmitFailController(_, API, $state, IntercomFactory, $window, $timeout){
		var vm = this;
		
		vm.submit = function(){
			$state.go('logout');
		}
	}
	
})();
