//==============================================
// TransAct Category Factory
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('TransActCategoriesFactory', function(API) {
		var factory = {};
		var prefix = 'trans_act/category';
		
		
		factory.getAll = function(type){
			return API.all(prefix).post({type: type});
		}
		
		return factory;
	});
})();