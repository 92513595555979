(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyAuthConfirmationController', VerifyFailedAuthenticationController);
	
	function VerifyFailedAuthenticationController(_, API, $state, user, IntercomFactory){
		var vm = this;
		
		vm.submit = function(){
			$state.go("private.app.dashboard.modal.verify.credit_summary");
		}
	}
	
})();
