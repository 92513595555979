(function() {
	"use strict";

	angular.module('app.controllers').controller('ForgotPasswordResetController', ForgotPasswordResetController);

	function ForgotPasswordResetController($log, $state, AuthFactory, ToastService, $auth, $stateParams) {	
		var vm = this;
		
		vm.submit = submit;
		
		function submit(){
			var data = {email: $stateParams.email, token : $stateParams.token, password: vm.password, password_confirmation: vm.confirm_password};
			
			console.log(data);
			
			AuthFactory.resetPassword(data)
				.then(function(user){
						var input = {email: user.email, password: data.password};
						
						$auth.login(input)
							.then(function(response){
								$log.info(response);
								$state.go('private.app.dashboard');
							}, function(){
								$state.go('public.login');				
							});					
				});
		}		
	}

})();
