//==============================================
// Contact Form Service
//==============================================

(function() {
	"use strict";

	angular.module('app.services').factory('ManualFactory', function(API) {
		var factory = {};
		
		factory.contact = function(data){
			return API.all('manual/contact').post(data);
		}
		
		return factory;
	});
})();
