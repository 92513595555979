(function() {
	"use strict";

	angular.module('app.controllers').controller('PropertyLocationController', PropertyLocationController);
	
	function PropertyLocationController(_, API, $auth, user, $state, $scope, $timeout, PropertyInterestFactory, PropertyLocationFactory, IntercomFactory, property_interest, provinces){
		var vm = this;
		vm.provinces = provinces;
		vm.submit = submit;
		vm.removeInterest = removeInterest;
		vm.autocompleteOptions = {
			componentRestrictions: { country: 'za' },
      types: ['geocode']
		};
		var location = {name: '', suburb: '', city: '', province: '', country: '', postal_code: '', lat: '', lng: ''};
		
		vm.back = function(){
			$state.go('private.app.dashboard.modal.property.price', {}, {reload: true});
		};
		
		vm.data = {};
		
		if(property_interest.hasOwnProperty('location')){
			vm.data	= property_interest.location;
		}
		
		vm.locations = [];
		
		if(!_.isEmpty(property_interest)){
			if(!_.isEmpty(property_interest.location)){
				vm.locations = property_interest.location;
			}
		}
		
		vm.searchProvince = function(val) {
			return PropertyLocationFactory.searchProvince(val)
				.then(function(resp){	
					return resp;
				});
  	};
  	
		vm.searchSuburb = function(val) {
			console.log(val);
			if(! vm.data.hasOwnProperty('province')){
				return;
			}
			return PropertyLocationFactory.searchSuburb(vm.data.province.id, val)
				.then(function(resp){	
					return resp;
				});
  	};
  	
  	vm.selectedProvince = function(){
	  	vm.provinceSelected = true;
  	}  	

		$scope.$on('g-places-autocomplete:select', function (event, data) {
			
			$timeout(function(){vm.full_address = '';}, 500);
			var loc = _(location).clone();
			loc.lat = data.geometry.location.lat();
			loc.lng = data.geometry.location.lng();			
			loc.name = data.formatted_address;
			
			angular.forEach(data.address_components, function(component) {
				angular.forEach(component.types, function(type){	            		    	 														
					if(type == 'sublocality_level_2'){
						loc.suburb = component.long_name;
						return;
					}

					if(type == 'sublocality_level_1'){
						if(loc.suburb == ''){
							loc.suburb = component.long_name;
							return;
						}
					}

					if(type == 'locality'){
						loc.city = component.long_name;
						return;
					}       

					if(type == 'administrative_area_level_1'){
						loc.province = component.long_name;
					}

					if(type == 'country'){
						loc.country = component.long_name;
						return;
					}

					if(type == 'postal_code'){
						loc.postal_code = component.long_name;
						return;
					} 		    	                    	    	  
				});	    	    
			}); 
			
			vm.locations.push(loc);
		});
		
		function removeInterest(index){
			vm.locations.splice(index, 1);
		}
		
		function submit(){			
			var data = {location: vm.data};

			PropertyInterestFactory.add(data)
				.then(function(){	
						$state.go('private.app.dashboard.modal.property.type');
				});
		}
	}
})();