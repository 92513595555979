(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyFailedQualificationController', VerifyFailedQualificationController);
	
	function VerifyFailedQualificationController(_, API, $state, user, IntercomFactory, snapshot){
		var vm = this;								
		
		if(snapshot.exclusion_reason != null){
			if(snapshot.exclusion_reason.hasOwnProperty('code')){
  				if(snapshot.exclusion_reason.code == 'D' || snapshot.exclusion_reason.code == 'L' || snapshot.exclusion_reason.code == 'SCS'){
    				vm.title = 'Unfortunately you do not qualify at this time';
        			vm.bodyCopy = 'Based on your current financial commitments we regret to inform you that your credit score is not high enough to produce an bond indicator. </br></br>You can call us on 0860006622 if you need any further assistance.';
        			vm.showLogout = true; 
  				}else{
    				if(snapshot.exclusion_reason.code == 'N' || (snapshot.score >= 1 && snapshot.score <= 6)){
      					vm.title = 'Unfortunately you may not qualify at this time';
          				vm.bodyCopy = 'Due to your low credit score, you are being redirected to one of our home loan experts. </br></br>Our experts will be in contact with you shortly to discuss alternative solutions for you. </br></br>You can call us on 0860006622 if you need any further assistance.';
          				vm.showLogout = true; 
    				}  		
  				}	  		
			}
		}	
		
		if(typeof vm.showLogout == "undefined"){
  		vm.title = 'Unfortunately you do not qualify at this time';
      vm.bodyCopy = 'We are sorry to inform you that at this stage your credit score is not high enough to produce a bond indicator! </br></br> However all is not lost – for your benefit we have partnered with Sanlam Credit Solutions to coach and guide you on your journey to improve your credit score.  If you want to improve your credit journey please click below and you’ll soon be in a position to own your own home!';
      vm.showSanlam = true;
		}		
		
		vm.submitLead = function(){
			$state.go('private.app.dashboard.modal.verify.lead_submit', {type:"credit"});
		}
		
		vm.submit = function(){
			$state.go('private.app.dashboard.modal.verify.assistance');
		}
		
		vm.logout = function(){
			$state.go('logout');
		}		
	}
	
})();
