(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyConfirmIdController', VerifyConfirmIdController);
	
	function VerifyConfirmIdController(API, $state, $timeout, $log, CreditCrunchIdentity, CreditCrunchAuth, user, IntercomFactory){
		var vm = this;
		vm.confirm = confirm;
		vm.user = user;	
		
		function confirm(){			
			var data = {
					identifier : vm.identifier
			}
			
			CreditCrunchIdentity.create(data)
				.then(function(response){	
					$log.info(response);			
					IntercomFactory.trackEvent('IDnumber_Entered');
					
					CreditCrunchAuth.getMobile()
					.then(function(response){
						$state.go('private.app.dashboard.modal.verify.credit_summary');
					}, function(err){
						$state.go('private.app.dashboard.modal.verify.questions');											
					})
			});
		}
	}
	
})();
