(function() {
	"use strict";

	angular.module('app.controllers').controller('RegisterController', RegisterController);

	function RegisterController(_, $scope, $window, $log, $auth, $state, $timeout, ToastService, $sessionStorage, $analytics) {	
		var vm = this;
		//$scope.myForm.first_name.$setValidity('required', false);
		vm.register = register;
		
		function register(){
			var user = {
					email: vm.email,
					password: vm.password,
					first_name: vm.first_name,
					surname: vm.surname					
			};			
			
			$auth.signup(user)
				.then(function(response){
					$log.info(response);
					
					if(! _.isEmpty($sessionStorage.tracking_params)){
						user.tracking_parameters = $sessionStorage.tracking_params;
					}
						
				$auth.login(user)
					.then(function(response){
						$log.info(response);		   								
						$analytics.eventTrack('oBI Registration Successful', {  category: 'Form'});									
            $timeout(function(){$analytics.eventTrack('OBiRegistrations');}, 1);   						
						$state.go('private.app.dashboard');
					}, registerFail);
				}, registerFail);
		}
		
		function registerFail(response){
			var msg = '';
			console.log(response);
			if(!_.isEmpty(response.data.errors)){
				msg = response.data.errors[0];
			}else{
				msg = 'Error occured while registering';
			}
			
			ToastService.error(msg);
		}
		
	}

})();
