//==============================================
// User Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('UserFactory', function(API, $localStorage, $q) {
		var factory = {};
		
		factory.get = function(){
			var deferred = $q.defer();
			
			API.one('user').get()
				.then(function(response){
					$localStorage.user = response;
					deferred.resolve(response);
				}, function(err){
					deferred.reject(err);
				});
				
				return deferred.promise;
		}
		
		factory.postProfile = function(data){
			return API.all('user/profile').post({Profile: data});
		}
		
		return factory;
	});
})();