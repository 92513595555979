(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyCreditSummaryController', VerifyCreditSummaryController);
	
	function VerifyCreditSummaryController(_, $state, $timeout, $log, $filter, snapshot, IntercomFactory){
		var vm = this;
		vm.snapshot = snapshot;	
		vm.submit = submit;
				
		vm.noScoreGraph = {};
		vm.graph = {};
		vm.score_bands = {};
				
		if(! _.isEmpty(snapshot)){
			initScoreGraph();
			IntercomFactory.trackEvent('Credit_Summary_Presented');
		}else{
			initNoScoreGraph();
		}
		
		function initNoScoreGraph(){
			vm.snapshot = {
				total_debt: 0,
				total_instalments: 0,
				currency: 'R',
				total_arrears: 0,
				enquiries: 0,
				fraud_notices: 0,
				negative_info: 0
			};
			
			vm.noScoreGraph = {
				options: {
					circleColor: '#7DDF1E',
					textColor: '#7DDF1E',
					waveTextColor: '#FFFFFF',
					waveColor: '#7DDF1E',
					displayPercent: false	,
					minValue: 0,
					maxValue: 1000		
				},
				value: 0
			};
		}
		
		function initScoreGraph(){
			vm.graph = {options: {}, data: {}, score_colours: {}}

			/*vm.score_bands = _.clone(snapshot.score_bands);
			
			angular.forEach(vm.score_bands, function(val, state){
				if(state == 'excellent'){
					vm.score_bands[state].colour = '#33A8FF';
					return;
				}
				
				if(state == 'very good'){
					vm.score_bands[state].colour = '#4D9B01';
					return;
				}
				
				if(state == 'good'){
					vm.score_bands[state].colour = '#73CD1F';
					return;
				}
				
				if(state == 'fair'){
					vm.score_bands[state].colour = '#F47120';
					return;
				}
				
				if(state == 'poor'){
					vm.score_bands[state].colour = '#FF3f52';
					return;
				}
			});*/
			
			vm.score_bands = _.clone(snapshot.ooba.ooba_score_bands);
			_.sortBy(vm.score_bands, 'order');		
	
			vm.graph.options = {
				chart: {
					title: $filter('number')(snapshot.score, 0),
					type: 'pieChart',
	        height: 300,
	        donut: true,
	        x: function(d){return d.key;},
	        y: function(d){return d.y;},
	        showLabels: false,
	        showLegend: false,
	        duration: 1000,    
	        pie: {
	            startAngle: function(d) { return (d.startAngle/2 -Math.PI/2) },
	            endAngle: function(d) { return d.endAngle/2 -Math.PI/2 }
	        },  
	        tooltip: {
		        enabled: false
	        }
				}
			};				
			
			vm.graph.data = [
		    {
		        key: snapshot.score,
		        y: snapshot.score,
		        color: getScoreColour(snapshot.score)
		    },
		    {
		        key: '',
		        y: snapshot.max_score - snapshot.score,
		        color: '#EEEEEE'
		    }
	    ];
		}

 
    function submit(){
			$state.go("private.app.dashboard.modal.affordability.start", {}, {reload: true});
    }
    
    function getScoreColour(score){
			var colour = '#000';
			angular.forEach(vm.score_bands, function(band){

				if(score >= band.min && score <= band.max){
					colour = '#'+band.colour;
				}
			});
			
			return colour;
		}
	}
	
})();
