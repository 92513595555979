
// =========================================================================
    // Back Button Directive
    // =========================================================================

    // use history.back to navigate backwards
    
(function(){
    "use strict";

		angular.module('app.directives').directive("btn", function($http) {
			
		  return {
			  restrict: 'C',			  
			  link: function(scope, element, attrs) {
			    scope.$watch(function() {
			      return $http.pendingRequests.length > 0;
			    }, function(request) {
			      console.log(request);
			      if (!request) {
			        element.attr('disabled', false);
			      } else {
							element.attr('disabled', true);
			      }
			    });
			  }
		  }
		});
})();