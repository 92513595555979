(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyLeadSubmitController', VerifyLeadSubmitController);
	
	function VerifyLeadSubmitController(_, API, cfpLoadingBar, $state, IntercomFactory, $sce, $window, $timeout, lead, $stateParams, EventFactory, $analytics){
		var vm = this;
	//	cfpLoadingBar.start();
	
		if($stateParams.hasOwnProperty('type')){
			if($stateParams.type == 'credit'){
				EventFactory.trigger('CreditCrunchQualificationFailed');		
				$analytics.eventTrack('Sanlam Credit Solutions - Credit Score Failed', {category: 'Form'});					
			}else{			
				if($stateParams.type == 'affordability'){
					EventFactory.trigger('AffordabilityFailed');		
					$analytics.eventTrack('Sanlam Credit Solutions - Affordability', {  category: 'Form'});							
				}
			}
		}
		
		$timeout(function(){
			$window.location.href = lead.url;			
		}, 2000)
	}
	
})();
