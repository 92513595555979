(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityMfaController', AffordabilityMfaController);
	
	function AffordabilityMfaController(API, $state, TransActAccountFactory, $stateParams, mfaFields){
		var vm = this;
		vm.data = {};
		vm.data.site_id = $stateParams.site_id;
		
		vm.mfaFields = mfaFields;
		vm.submit = submit;
		
		function submit(){
			var data = vm.data;
			TransActAccountFactory.postChallenge($stateParams.site_id, data)
				.then(function(){
					$state.go('private.app.dashboard.modal.affordability.progress', {site_id: $stateParams.site_id});
				});
		}
	}
	
})();
