(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityConfirmExpensesController', AffordabilityConfirmExpensesController);
	
	function AffordabilityConfirmExpensesController(_, API, $state, $log, TransActTransactionFactory, transactions){
		var vm = this;
		vm.transactions = transactions;
		vm.data = {};
		vm.data.Transactions = {};
		vm.manualExpense = {};
		
		_.each(transactions, function(t, key){
	    	var state = false;
	    	if(t.score > 0){
	    		state = true;
	    	}
	    	
	    	vm.data.Transactions[t._id] = state;
	    	vm.total = calcTotal();
	    });

	    vm.checkTransaction = function(){	    	
	    	vm.total = calcTotal();
	    	return vm.total;
	    }
	    
	    function calcTotal(){
	    	var total = 0;
	    	_.each(vm.data.Transactions, function(state, tid){
	    		
	    		if(state === true){	    			
	    			total += Math.abs(getTransactionAmount(tid));
	    		}
		    });
	    	
	    	return total;
	    }
	    
	    function getTransactionAmount(tid){
	    	var amount = 0;
	    	_.each(transactions, function(t, key){
	    		if(tid == t._id){
	    			amount = t.amount;
	    		}
	    	});
	    	
	    	return amount;
	    }

		
			
		vm.submit = function(){
			var proceed = false;
			_.each(vm.data, function(status, account){
	    		if(status){
	    			proceed = true;
	    		}
	    	});
	    	
	    	if(proceed == false){
		    	return;
	    	}
	    	
	    	TransActTransactionFactory.postExpenses(vm.data)
	    		.then(function(expenses){
						$state.go('private.app.dashboard.modal.affordability.review_income');
	    		}, function(error){
	    			$state.go('private.app.dashboard.modal.affordability.progress');
	    		});
		}
	}
})();