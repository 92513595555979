(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyQuestionsController', VerifyQuestionsController);
	
	function VerifyQuestionsController(CreditCrunchAuth, $timeout, $state, $log, questions, WidgetService, IntercomFactory){
		var vm = this;
		
		vm.myForm = {};
		vm.answers = {};
		vm.questions = questions;		
		vm.questionCount = questions.length;		
		vm.getQuestion = getQuestion;
		
		function getQuestion(q){				
			$log.info('Fetching Question ' + q);
			var question;
			
			if(q > vm.questionCount){
				submit(vm.answers);
				return;
			}
			
			if(q <= vm.questionCount){				
				$timeout(function(){
					vm.myForm.$setPristine();
				});
				
				question = questions[q-1];
				question.number = q;
			}else{
				question = getQuestion[1];
				question.number = 1;
			}
			
			if(q != 1){
				$timeout(function(){
					vm.question = question;				
				}, 700)
			}else{
				vm.question = question;
			}
			
		}	  
		
		function submit(answers){
			CreditCrunchAuth.postAnswers(answers)
					.then(function(response){	
						$log.info(response);	
						IntercomFactory.trackEvent('Security_Questions_Passed');
						$state.go('private.app.dashboard.modal.verify.credit_summary');
					}, function(err){
						$log.error(err);
						IntercomFactory.trackEvent('Security_Questions_Failed');
						$state.go($state.current, {}, {reload: true});
					});
		}
		
		getQuestion(1);
		IntercomFactory.trackEvent('Security_Questions_Presented');
	} 
	
})();
