(function() {
	"use strict";

	angular.module('finlayer').factory('TransActAccountFactory', function(API) {
		var factory = {};
		var prefix = 'finlayer/trans_act/account';
		
		factory.postLogin = function(data, site_id){			
			var url;
			
			if(site_id != null){
				url = prefix + '/login/' + site_id;
			}else{
				url = prefix + '/login'
			}
			
			return API.all(url).post(data);
		}
		
		factory.getProgress = function(site_id){       
        return API.one(prefix + '/progress/' + site_id).withHttpConfig({ignoreLoadingBar: true}).get();        
    }
    
    factory.getProgressAll = function(){       
        return API.one(prefix + '/progress-enabled').withHttpConfig({ignoreLoadingBar: true}).get();        
    }
    
    factory.getAll = function(){       
        return API.all(prefix).getList();        
    }
    
    factory.postEnable = function(data){
	    return API.all(prefix + '/enable').post(data);
    }
    
    factory.getChallenge = function(site_id){       
        return API.one(prefix + '/challenge/'+site_id).get();        
    }
    
    factory.postChallenge = function(site_id, data){
	    return API.all(prefix + '/challenge/'+site_id).post(data);
    }

		return factory;
	});
})();
