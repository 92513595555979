(function() {
	"use strict";

	angular.module('app.controllers').controller('CertificateWidgetSummaryController', CertificateWidgetSummaryController);
	
	function CertificateWidgetSummaryController(_, $log, affordability, snapshot, user, assets, certificate_count){
		var vm = this;
		
		vm.certificate_count = certificate_count;
		vm.showCertificates = false;		
		vm.scoreGraph = {
			options: {
				circleColor: '#7DDF1E',
				textColor: '#7DDF1E',
				waveTextColor: '#FFFFFF',
				waveColor: '#7DDF1E',
				displayPercent: true,	
				minValue: 0,
				maxValue: 100
			}			
		};
		vm.scoreGraph.value = 0;
		
		if(!_.isEmpty(affordability)){
			if(affordability.hasOwnProperty('income')){
				if(affordability.income > 0){
					vm.scoreGraph.value += 50;	
				}
			}
		}
		
		if(!_.isEmpty(snapshot)){
			if(snapshot.hasOwnProperty('score')){
				vm.scoreGraph.value += 50;
			}
		}
		
		if(!_.isEmpty(user.profile)){
			/*if(!_.isEmpty(user.profile.home_address)){
				vm.scoreGraph.value  += 30;	
			}*/
		}						
		
		if(vm.scoreGraph.value == 100){
			vm.showCertificates = true;
		}		
	}	
})();
