(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityMoneySummaryController', AffordabilityMoneySummaryController);
	
	function AffordabilityMoneySummaryController(_, API, $state, $sce, pdfLink, IntercomFactory){
		angular.element('.modal-dialog').addClass('w-900');
		var vm = this;
		vm.submit = submit;
		vm.pdfLink = pdfLink;
		vm.iframeSrc = $sce.trustAsResourceUrl(vm.pdfLink.link);		
		
		vm.iframeComplete = function(){
			angular.element('.iframe-container .preloader').fadeOut();
		}
		function submit(){
	    $state.go('private.app.dashboard', {}, {reload: true});
    }
    
    IntercomFactory.trackEvent('Affordability_Presented_MS');	
	}
})();