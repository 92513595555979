(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityStatementPasswordController', AffordabilityStatementPasswordController);
	
	function AffordabilityStatementPasswordController(_, $state, $stateParams, BankStatementStatementFactory){
		var vm = this;
		vm.data = {};	
		vm.submit = submit;			
		
		function submit(){
			var input = {
				'asset_id': $stateParams.asset_id,
				'bank_id': $stateParams.bank_id,
				'statement_date':  $stateParams.statement_date,
				'password' : vm.data.password
			};
						
			BankStatementStatementFactory.postParse(input)
				.then(function(){
					$state.go('private.app.dashboard.modal.affordability.accounts', {source: 'upload'});
				}, function(err){
					if(err.data.status_code == 403){
						
					}else{
						$state.go('private.app.dashboard.modal.affordability.statement_upload', {bank_id: $stateParams.bank_id});						
					}						
				});			
		}
	}
})();