(function() {
	"use strict";

	angular.module('finlayer').factory('CreditCrunchIdentity', function(API) {
		var factory = {};
		
		factory.create = function(data){
			return API.all('finlayer/credit_crunch/identity').post(data);
		}
		
		factory.get = function(){
			return API.one('finlayer/credit_crunch/identity').get();
		}
		
		return factory;
	});
})();
