(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityGrossIncomeController', AffordabilityGrossIncomeController);
	
	function AffordabilityGrossIncomeController(_, API, $state, $log, TransActAffordabilityFactory, IntercomFactory, affordability, snapshot){
		var vm = this;
		vm.affordability = affordability;
		
		if(affordability.gross_income_confirmed == 0){
			vm.gross_income = affordability.gross_income_suggested;	
		}else{
			vm.gross_income = affordability.gross_income_confirmed ;
		}

		vm.submit = submit;
		
		function submit(){
			var data = vm.gross_income;
			TransActAffordabilityFactory.postGrossIncome(data)
				.then(function(){
					IntercomFactory.trackEvent('Affordability_Confirmed_Gross_Income');	
					/*if(_.isEmpty(snapshot)){
						$state.go('private.app.dashboard.modal.affordability.confirm_expenses');	
					}else{
						$state.go('private.app.dashboard.modal.affordability.debt_repayments');
					}	*/
					
					$state.go('private.app.dashboard.modal.affordability.confirm_expenses');				
				});
		}
	}
})();