(function() {
	"use strict";

	angular.module('app.controllers').controller('CodeController', CodeController);

	function CodeController($log, $auth, $state, $stateParams, cfpLoadingBar, ToastService, $localStorage, $sessionStorage) {	
		var vm = this;
		var user = {
				code: $stateParams.code
		};
		cfpLoadingBar.start();
		var options = {url:'/api/auth/code'};
		
		if(! _.isEmpty($localStorage.mbf_cid)){
			user.mbf_cid = $localStorage.mbf_cid;
		}
		
		if(! _.isEmpty($sessionStorage.var1)){
			user.var1 = $sessionStorage.var1;
		}

		if(! _.isEmpty($sessionStorage.var2)){
			user.var2 = $sessionStorage.var2;
		}
		
		if(! _.isEmpty($sessionStorage.var3)){
			user.var3 = $sessionStorage.var3;
		}
		
		if(! _.isEmpty($sessionStorage.var4)){
			user.var4 = $sessionStorage.var4;
		}
		
		if(! _.isEmpty($sessionStorage.var5)){
			user.var5 = $sessionStorage.var5;
		}				
		
		$auth.login(user, options)
			.then(function(response){
				$log.info(response);
				$state.go('private.app.dashboard');
			}, loginFail);
		
		function loginFail(response){
			$log.error(response);
			ToastService.error('Error occured'); 
			$state.go('public.login');
		}
		
	}

})();
