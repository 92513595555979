//==============================================
// Province Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('EmployerTypeFactory', function($q) {
		var factory = {};
		var types = [
			'Commission Based',
			'Contract',
			'Full Time',
			'Self Employed',
			'Unemployed '
		];
		
		factory.getAll = function(){
			var deferred = $q.defer();			
			deferred.resolve(types);
			return deferred.promise;
		}
		
		return factory;
	});
})();