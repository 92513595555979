//==============================================
// Widget Shared Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('WidgetService', function() {
		var factory = {};
		
		factory.enterAnimation = function(){
			angular.element('.modal-content').addClass('animated').removeClass('flipOutX').addClass('flipInX');
		}
		
		factory.exitAnimation = function(){
			angular.element('.modal-content').addClass('animated').removeClass('flipInX').addClass('flipOutX');
		}
		
		return factory;
	});
})();