(function(){
	"use strict";

	angular.module('app.routes').config(function($stateProvider, $urlRouterProvider){

		var getView = function(viewName, parent){
			var src;
			if(parent != null){
				src = './views/app/' + parent + '/' + viewName + '/' + viewName + '.html';
			}else{
				src = './views/app/' + viewName + '/' + viewName + '.html'; 
			}
			return src;
		};
		
		var getLayout = function(viewName){
			return './views/app/layouts/' + viewName + '.html';
		};

		$urlRouterProvider.otherwise('/');

		$stateProvider
		.state('app', {
			abstract: true,	
			template: '<div class="table-layout" ui-view></div>',
			resolve: { 
				brand: ['BrandingFactory', '$q', '$localStorage', function(BrandingFactory, $q, $localStorage){
					var deferred = $q.defer();
					BrandingFactory.get()
						.then(function(brand){
							$localStorage.brand = brand;
							deferred.resolve(brand);
						});
					return deferred.promise;
				}]
			},
			controller: function($rootScope, brand){
				$rootScope.Brand = brand;
			}
		})
		.state('public', {	
				parent: 'app',
				abstract: true,				
				templateUrl: getLayout('public'),
				resolve:{
					init: ['_', '$stateParams', '$sessionStorage', '$location', 'CookieFactory', '$window', function(_, $stateParams, $sessionStorage, $location, CookieFactory, $window){						
						var query_params = $location.search();
						var cookies = CookieFactory.getAll();
						console.log(query_params);
						$sessionStorage.tracking_params = {
							'cookies': cookies,
							'query_params': query_params
						};																						
					}]
				}, 
				controller: function(IntercomFactory, $location){
					IntercomFactory.init();
					console.log('window');
					console.log($location.search());
				}
		})
		.state('public.login', {
			url: '/login',
			data: {}, 
			views:{
				main: {
					templateUrl: getView('login', null),
					controller: 'LoginController',
					controllerAs: 'vm'
				}	
			}							
		})
		.state('public.auth_code', {
			url: '/code?code',
			views:{
				main: {
					templateUrl: getView('code', null),
					controller: 'CodeController',
					controllerAs: 'vm'
				}	
			}							
		})
		.state('public.autologin', {
			url: '/autologin?token',
			views:{
				main: {
					templateUrl: getView('autologin', null),
					controller: 'AutologinController',
					controllerAs: 'vm'
				}	
			}							
		})		
		.state('public.register', {
			url: '/',
			data: {}, 
			views:{
				main: {
					templateUrl: getView('register_classic', null),
					controller: 'RegisterController',
					controllerAs: 'vm'
				}	
			}								
		})
		.state('public.register2', {
			url: '/register2',
			data: {}, 
			views:{
				main: {
					templateUrl: getView('register2', null),
					controller: 'RegisterController',
					controllerAs: 'vm'
				}	
			}								
		})		
		.state('public.forgot_password', {
			abstract:true						
		})
		.state('public.forgot_password.email', {
			data: {}, 
			url:'/email',
			views:{
				'main@public': {
					templateUrl: getView('email', 'widgets/forgot_password'),
					controller: 'ForgotPasswordEmailController',
					controllerAs: 'vm'
				}	
			}							
		})
		.state('public.forgot_password.reset', {
			data: {}, 
			url:'/reset-password/{email}/{token}',
			views:{
				'main@public': {
					templateUrl: getView('reset', 'widgets/forgot_password'),
					controller: 'ForgotPasswordResetController',
					controllerAs: 'vm'
				}	
			},
			resolve:{
				init: ['_', '$q', '$state', '$stateParams', 'AuthFactory', function(_, $q, $state, $stateParams, AuthFactory){
					var deferred = $q.defer();
					
					if(!_.isEmpty($stateParams.email) && !_.isEmpty($stateParams.token)){
						deferred.resolve();
						var data = {
							email: $stateParams.email,
							token: $stateParams.token
						};
						AuthFactory.validatePasswordResetToken(data)
							.then(function(){
								deferred.resolve();
							}, function(){
								deferred.reject();
								$state.go('public.login');
							});						
					}else{
						deferred.reject();
						$state.go('public.login');
					}
					
					return deferred.promise;
				}]
			}							
		})
		.state('private', {
			parent: 'app',
			abstract: true,
			templateUrl: getLayout('app'),
			resolve: {
				authenticated: function($q, $auth, $state, $log){
					var deferred = $q.defer();
					if ($auth.isAuthenticated()) {
						$log.info('User is Authenticated');
						deferred.resolve();
					}else{
						$log.info('User is  not Authenticated');
						$state.go('public.login');
						deferred.reject();
					}
					return deferred.promise;		    
				}, 
				user: function(UserFactory, $log){
					$log.info('Getting user');
					return UserFactory.get();
				},
				affordability: ['TransActAffordabilityFactory', '$q', function(TransActAffordabilityFactory, $q){
					var deferred = $q.defer();
						TransActAffordabilityFactory.get()
							.then(function(response){
								deferred.resolve(response);
							}, function(){
								deferred.resolve({});								
							});
					return deferred.promise;
				}],
				snapshot: ['CreditCrunchSnapshotFactory', '$q', function(CreditCrunchSnapshotFactory, $q){
					var deferred = $q.defer();
						CreditCrunchSnapshotFactory.get()
							.then(function(response){
								deferred.resolve(response);
							}, function(){
								deferred.resolve({});								
							});
					return deferred.promise;
				}],
				assets: ['ShiftCtrlAssetFactory', '$q', function(ShiftCtrlAssetFactory, $q){
					var deferred = $q.defer();
						ShiftCtrlAssetFactory.getAll()
							.then(function(response){
								deferred.resolve(response);
							}, function(){
								deferred.resolve({});								
							});
					return deferred.promise;
				}],
				property_interest: ['PropertyInterestFactory', '$q', function(PropertyInterestFactory, $q){
					var deferred = $q.defer();
						PropertyInterestFactory.get()
							.then(function(response){
								deferred.resolve(response);
							}, function(){
								deferred.resolve({});								
							});
					return deferred.promise;
				}],
				certificate_count: ['CertificateFactory', '$q', function(CertificateFactory, $q){
					var deferred = $q.defer();
						CertificateFactory.getCount()
							.then(function(response){
								deferred.resolve(response);
							}, function(){
								deferred.resolve({});								
							});
					return deferred.promise;
				}],
				concierge_certificate_count: ['CertificateFactory', '$q', function(CertificateFactory, $q){
					var deferred = $q.defer();
						CertificateFactory.getConciergeCount()
							.then(function(response){
								deferred.resolve(response);
							}, function(){
								deferred.resolve({});								
							});
					return deferred.promise;
				}]				
			},
			controller: 'PrivateController',
			controllerAs:'PrivateCtrl'
		})
		.state('private.app', {
			abstract: true,
			data: {}, 
			views:{
				header: {
					templateUrl: getView('header', null),
					controller: 'HeaderController',
					controllerAs: 'vm'
				},
				main: {},
				footer: {
					templateUrl: getView('footer', null),
					controller: 'FooterController',
					controllerAs: 'vm'
				}
			}								
		})
		
		.state('private.app.dashboard_layout', {
			data: {}, 
			views:{
				'main@private': {
					templateUrl: getView('dashboard', null),
					controller: 'DashboardController',
					controllerAs: 'vm'
				}	
			}						
		})
		
		.state('private.app.dashboard', {
			parent: 'private.app.dashboard_layout',
			url: '/dashboard',
			data: {}, 
			views:{
				'profile': {
					templateUrl: getView('dashboard', 'widgets/profile'),
					controller: 'ProfileWidgetSummaryController',
					controllerAs: 'vm'
				},
				'otp': {
					templateUrl: getView('dashboard', 'widgets/otp')
				},				
				'verify': {
					templateUrl: getView('dashboard', 'widgets/verify'),
					controller: 'VerifyWidgetSummaryController',
					controllerAs: 'vm'
				},
				'certificate': {
					templateUrl: getView('dashboard', 'widgets/certificate'),
					controller: 'CertificateWidgetSummaryController',
					controllerAs: 'vm'
				},
				'affordability': {
					templateUrl: getView('dashboard', 'widgets/affordability'),
					controller: 'AffordabilityWidgetSummaryController',
					controllerAs: 'vm'
				},
				'property': {
					templateUrl: getView('dashboard', 'widgets/property'),
					controller: 'PropertyWidgetSummaryController',
					controllerAs: 'vm'
				},
				'fica': {
					templateUrl: getView('dashboard', 'widgets/fica')
				}	
			}					
		})
		
		.state('private.app.dashboard.modal', {
			abstract: true,
			views: {
				'modal@private.app.dashboard_layout': {
					templateUrl: getLayout('modal')
				}
			},
			onEnter: ["$state", function($state) {
				angular.element(document).on("keyup", function(e) {
					if(e.keyCode == 27) {
						//angular.element(document).off("keyup");
						//$state.go("private.app.dashboard");
					}
				});
				
				angular.element(document).on("click", ".modal-backdrop, .modal", function() {
					//$state.go("private.app.dashboard");
				});

				angular.element(document).on("click", ".modal-content *", function(e) {
					e.stopPropagation();
				});
			}],
			onExit: function(){
				
			}
		})
		
		.state('private.app.dashboard.modal.checklist', { 
			url:'/checklist',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('checklist'),
					controller: 'CheckListController',
					controllerAs: 'vm'					
				}
			} 
		})
		
		.state('private.app.dashboard.modal.contact', {
			abstract: true
		})		
		.state('private.app.dashboard.modal.contact.form', { 
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('form', 'widgets/contact'),
					controller: 'ContactFormController',
					controllerAs: 'vm'					
				}
			} 
		})
		.state('private.app.dashboard.modal.contact.end', { 
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('end', 'widgets/contact')
				}
			} 
		})
		.state('private.app.dashboard.modal.contact.error', { 
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('error', 'widgets/contact')
				}
			} 
		})
		
		
		.state('private.app.dashboard.modal.verify', {
			abstract: true
		})
		
		.state('private.app.dashboard.modal.verify.start', { 
			template: '',
			url: '/start',
			resolve: {
				init: ['_', '$log', '$q', '$state', 'CreditCrunchAuth', 'CreditCrunchIdentity', 'user', 'snapshot', 'assets', function(_, $log, $q, $state, CreditCrunchAuth, CreditCrunchIdentity, user, snapshot, assets){
					var deferred = $q.defer();
					
					$log.debug('Initializing Verify Widget');
					if(!_.isEmpty(snapshot)){
						$log.debug(snapshot);
						$log.debug('Snapshot Available');
						$state.go('private.app.dashboard.modal.verify.credit_summary');
						deferred.reject();
					}else{
						$log.debug('No Snapshot');
						CreditCrunchAuth.getAuthState()
							.then(function(){
									$log.debug('Auth State True');
									
									if(!_.isEmpty(user.profile)){
										if(user.profile.hasOwnProperty('failed_authentication')){
											if(user.profile.failed_authentication == true){
												$state.go('private.app.dashboard.modal.verify.auth_confirmation');
												deferred.reject();				 
												return;								
											}
										}
									}
									
									$state.go('private.app.dashboard.modal.verify.credit_summary');
									deferred.reject();
							}, function(){
								$log.debug('Auth State False');

								CreditCrunchIdentity.get()
									.then(function(){
										$log.debug('User has Identity');
										
										if(!_.isEmpty(user.profile)){
											if(user.profile.hasOwnProperty('failed_authentication')){
												if(user.profile.failed_authentication == true){
													$log.debug('Auth Failed');						
															if(!_.isEmpty(assets)){
																var required_docs = ['id', 'residence'];
																var available_docs = [];
																	
																_.each(required_docs, function(doc_name, i){
																	_.each(assets, function(asset, j){
																		if(asset.description == doc_name){
																			available_docs.push(doc_name);
																		}
																	});
																});
													
																if(available_docs.length == required_docs.length){
																	$state.go('private.app.dashboard.modal.fica.complete');
																}else{
																	$state.go('private.app.dashboard.modal.fica.upload');
																}
															}else{
																$state.go('private.app.dashboard.modal.verify.questions');
															}																														
												}else{
													$state.go('private.app.dashboard.modal.verify.questions');
												}											
											}else{
												$state.go('private.app.dashboard.modal.verify.questions');
											}		
										}else{
											$state.go('private.app.dashboard.modal.verify.questions');
										}																												

										deferred.reject();	
									}, function(){
										$log.debug('User has no Identity');
										$state.go('private.app.dashboard.modal.verify.confirm_id');
										deferred.reject();	
									});
								
							})
					}				
										
					return deferred.promise;
				}]

			}
		})
		
		.state('private.app.dashboard.modal.verify.confirm_id', { 
			url:'/confirm_id',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('confirm_id', 'widgets/verify'),
					controller: 'VerifyConfirmIdController',
					controllerAs: 'vm'					
				}
			} 
		})
		
		.state('private.app.dashboard.modal.verify.questions', {
			url:'/questions',			
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('questions', 'widgets/verify'),
					controller: 'VerifyQuestionsController',
					controllerAs: 'vm'					
				}
			},
			resolve: {
				questions: ['CreditCrunchAuth', '$q', '$state', 'IntercomFactory', function(CreditCrunchAuth, $q, $state, IntercomFactory){
					var deferred = $q.defer();
					
					CreditCrunchAuth.getQuestions()
						.then(function(response){
							deferred.resolve(response);
						}, function(err){
								if(err.status == 429){
									deferred.reject(err);								
									$state.go('private.app.dashboard.modal.verify.failed_authentication');								
								}else{
									if(err.status == 417){
										deferred.reject(err);
										$state.go('private.app.dashboard.modal.verify.thin_file_questions');										
									}
								}
						});
					return deferred.promise;
				}]
			} 							
		})
		.state('private.app.dashboard.modal.verify.thin_file_questions', { 
			url: '/thin_file_questions',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('thin_file_questions', 'widgets/verify'),
					controller: 'VerifyThinFileQuestionsController',
					controllerAs: 'vm'					
				}
			} 
		})	
		.state('private.app.dashboard.modal.verify.thin_file', { 
			url: '/thin_file',			
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('thin_file', 'widgets/verify'),
					controller: 'VerifyThinFileController',
					controllerAs: 'vm'					
				}
			} 
		})	
		.state('private.app.dashboard.modal.verify.failed_qualification', { 
			url:'/failed_qualification',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('failed_qualification', 'widgets/verify'),
					controller: 'VerifyFailedQualificationController',
					controllerAs: 'vm'					
				}
			},
			resolve: {
			  snapshot: function(CreditCrunchSnapshotFactory){
				return CreditCrunchSnapshotFactory.get();
			  }
			} 
		})
		.state('private.app.dashboard.modal.verify.lead_submit', { 
			url:'/lead_submit?type',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('lead_submit', 'widgets/verify'),
					controller: 'VerifyLeadSubmitController',
					controllerAs: 'vm'					
				}
			}, 
			resolve: {
				lead: function(LeadFactory, $q, $state){
					var deferred = $q.defer();
					
					LeadFactory.send()
						.then(function(response){
							deferred.resolve(response);
						}, function(err){
								$state.go('private.app.dashboard.modal.verify.lead_submit_fail');	
						});
					return deferred.promise;					  
				},
			}  
		})	
		.state('private.app.dashboard.modal.verify.lead_submit_fail', { 
			url:'/lead_submit_fail',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('lead_submit_fail', 'widgets/verify'),
					controller: 'VerifyLeadSubmitFailController',
					controllerAs: 'vm'					
				}
			} 
		})				
		.state('private.app.dashboard.modal.verify.assistance', { 
			url:'/assistance',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('assistance', 'widgets/verify'),
					controller: 'VerifyAssistanceController',
					controllerAs: 'vm'					
				}
			} 
		})			
		.state('private.app.dashboard.modal.verify.failed_authentication', { 
			url: '/failed_authentication',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('failed_authentication', 'widgets/verify'),
					controller: 'VerifyFailedAuthenticationController',
					controllerAs: 'vm'					
				}
			} 
		})
		.state('private.app.dashboard.modal.verify.failed_report', { 
			url: '/failed_report',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('failed_report', 'widgets/verify'),
					controller: 'VerifyFailedReportController',
					controllerAs: 'vm'					
				}
			} 
		})		
		.state('private.app.dashboard.modal.verify.auth_confirmation', { 
			url: '/auth_confirmation',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('auth_confirmation', 'widgets/verify'),
					controller: 'VerifyAuthConfirmationController',
					controllerAs: 'vm'					
				}
			} 
		})		
		.state('private.app.dashboard.modal.verify.credit_summary', {
			url: '/credit_summary',
			params: {
				thin_file: null
			},
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('credit_summary', 'widgets/verify'),
					controller: 'VerifyCreditSummaryController',
					controllerAs: 'vm'					
				}
			},
			resolve: {
				snapshot: ['_', 'CreditCrunchSnapshotFactory', '$stateParams', '$q', '$state', function(_, CreditCrunchSnapshotFactory, $stateParams, $q, $state){
					var deferred = $q.defer();
					
					if($stateParams.thin_file == null){
						CreditCrunchSnapshotFactory.get()
						.then(function(snapshot){
							/*if(snapshot.score >= 5){
								deferred.resolve(snapshot);
							}elseif(snapshot.qualifies == false){
								$state.go('private.app.dashboard.modal.verify.failed_qualification', {track: true});								
								deferred.reject({});																
							}else{
								$state.go('private.app.dashboard.modal.verify.thin_file');
								deferred.reject({});								
							}	*/		
							
							if(snapshot.qualifies){
								deferred.resolve(snapshot);
							}else{
								$state.go('private.app.dashboard.modal.verify.failed_qualification');								
								deferred.reject({});																
							}																
						}, function(err){
								if(err.status == 417){
									deferred.reject(err);
									$state.go('private.app.dashboard.modal.verify.thin_file');								
								}else{
									deferred.reject(err);
									$state.go('private.app.dashboard.modal.verify.failed_report');						
								}								
						});
					}else{
						deferred.resolve({});
					}
					
											
						return deferred.promise;
				}]
			} 							
		})
		
				
		.state('private.app.dashboard.modal.profile', {
			abstract: true,
			resolve: {
				user: ['UserFactory', '$log', function(UserFactory, $log){
					return UserFactory.get();
				}]
			}
		})
		
		.state('private.app.dashboard.modal.profile.address', {   
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('address', 'widgets/profile'),
					controller: 'ProfileAddressController',
					controllerAs: 'vm'				
				}
			},
			resolve: {
				provinces: ['ProvinceFactory', function(ProvinceFactory){
					return ProvinceFactory.getAll();
				}]
			}     
		})
		
		.state('private.app.dashboard.modal.profile.personal', {   
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('personal', 'widgets/profile'),
					controller: 'ProfilePersonalController',
					controllerAs: 'vm'				
				}
			},
			resolve: {
				employerTypes: ['EmployerTypeFactory', function(EmployerTypeFactory){
					return EmployerTypeFactory.getAll();
				}]
			}   
		})
		
		.state('private.app.dashboard.modal.affordability', {
			abstract: true
		})
		.state('private.app.dashboard.modal.affordability.start', {   
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('start', 'widgets/affordability'),
					controller: 'AffordabilityStartController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				init: ['_', 'user', '$q', '$state', function(_, user, $q, $state){
					var deferred = $q.defer();

					if(!_.isEmpty(user.profile)){
						if(!_.isEmpty(user.profile.affordability_categories)){
							$state.go('private.app.dashboard.modal.affordability.review_summary', {'recalculate':true});
							deferred.reject();
						}else{
							$state.go('private.app.dashboard.modal.affordability.options');
							deferred.reject();
						}
					}else{ 
						$state.go('private.app.dashboard.modal.affordability.options');
						deferred.reject();
					}
					
					return deferred.promise;
				}]
			}  
		})
		
		.state('private.app.dashboard.modal.affordability.options', {   
			url: '/options',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('options', 'widgets/affordability'),
					controller: 'AffordabilityOptionsController',
					controllerAs: 'vm'
				}
			}   
		})
		
		.state('private.app.dashboard.modal.affordability.disclaimer', {   
			url: '/disclaimer',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('disclaimer', 'widgets/affordability'),
					controller: function(){
						angular.element('.modal-dialog').removeClass('w-900');			
					}
				}
			},
			resolve:{
				accounts: ['TransActAccountFactory', function(TransActAccountFactory){
					return TransActAccountFactory.getAll();
				}],
				init: ['_', '$state', '$q', 'affordability', function(_, $state, $q, accounts){
					var deferred = $q.defer();
					if(!_.isEmpty(accounts)){					
						$state.go('private.app.dashboard.modal.affordability.progress');
						deferred.reject();
					}else{
						deferred.resolve();
					}
					return deferred.promise;
				}]				
			}    
		})
		
		.state('private.app.dashboard.modal.affordability.statement_type', {  
			url:'/statement_type', 
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('statement_type', 'widgets/affordability'),		
					controller: 'AffordabilityStatementTypeController',
					controllerAs: 'vm'				
				}
			},
			resolve:{
				banks: ['TransActBankFactory', function(TransActBankFactory){
					return TransActBankFactory.getPopular();
				}]
			}  
		})		
		
		.state('private.app.dashboard.modal.affordability.statement_upload', {  
			url:'/statement_upload?bank_id', 
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('statement_upload', 'widgets/affordability'),		
					controller: 'AffordabilityStatementUploadController',
					controllerAs: 'vm'				
				}
			}, 
		})	
		
		.state('private.app.dashboard.modal.affordability.statement_password', {  
			url:'/statement_password?bank_id&statement_date&asset_id', 
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('statement_password', 'widgets/affordability'),		
					controller: 'AffordabilityStatementPasswordController',
					controllerAs: 'vm'				
				}
			}, 
		})					
		
		.state('private.app.dashboard.modal.affordability.login', {  
			url:'/login?site_id&error_code&error_msg', 
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('login', 'widgets/affordability'),		
					controller: 'AffordabilityLoginController',
					controllerAs: 'vm'				
				}
			},
			resolve:{
				banks: ['TransActBankFactory', function(TransActBankFactory){
					return TransActBankFactory.getPopular();
				}],
				site: ['$stateParams', 'TransActSiteFactory', function($stateParams, TransActSiteFactory){
					if($stateParams.site_id != null){
						return TransActSiteFactory.get($stateParams.site_id);
					}
					return null;
				}]
			}  
		})	
		
		.state('private.app.dashboard.modal.affordability.progress', {   
			url:'/progress?site_id',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('progress', 'widgets/affordability'),		
					controller: 'AffordabilityProgressController',
					controllerAs: 'vm'/*,
					params:{
						site_id: null
					}				*/
				}
			}   
		})	
		
		.state('private.app.dashboard.modal.affordability.mfa', {   
			url:'/mfa?site_id',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('mfa', 'widgets/affordability'),		
					controller: 'AffordabilityMfaController',
					controllerAs: 'vm'/*,
					params:{
						site_id: null
					}				*/
				}
			},
			resolve:{
				mfaFields: ['$state', '$stateParams', '$q', 'TransActAccountFactory', function($state, $stateParams, $q, TransActAccountFactory){
					var deferred = $q.defer;
					
					if($stateParams.hasOwnProperty('site_id')){
						return TransActAccountFactory.getChallenge($stateParams.site_id);	
					}else{
						$state.go('private.app.dashboard.modal.affordability.login');
						deferred.reject();				
					}
					
					return deferred.promise;		
				}]
			}   
		})	
		
		.state('private.app.dashboard.modal.affordability.accounts', {   
			url:'/accounts?source',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('accounts', 'widgets/affordability'),		
					controller: 'AffordabilityAccountsController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				accounts: ['TransActAccountFactory', function(TransActAccountFactory){
					return TransActAccountFactory.getAll();
				}]
			}  
		})
		
		.state('private.app.dashboard.modal.affordability.confirm_income', {   
			url:'/confirm_income',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('confirm_income', 'widgets/affordability'),		
					controller: 'AffordabilityConfirmIncomeController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				transactions: ['TransActTransactionFactory', function(TransActTransactionFactory){
					return TransActTransactionFactory.getIncome();
				}],
				categories: ['TransActCategoryFactory', function(TransActCategoryFactory){
					return TransActCategoryFactory.getAll();
				}]				
			}  
		})		
		.state('private.app.dashboard.modal.affordability.gross_income', {   
			url:'/gross_income',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('gross_income', 'widgets/affordability'),		
					controller: 'AffordabilityGrossIncomeController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				affordability: ['TransActAffordabilityFactory', function(TransActAffordabilityFactory){
					return TransActAffordabilityFactory.get();
				}]
			}  
		})
		.state('private.app.dashboard.modal.affordability.debt_repayments', {   
			url:'/debt_repayments',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('debt_repayments', 'widgets/affordability'),		
					controller: 'AffordabilityDebtRepaymentsController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				debt_repayments: ['CreditCrunchReportFactory', '$q', '$state', function(CreditCrunchReportFactory, $q, $state){
					var deferred = $q.defer();
					
					CreditCrunchReportFactory.getDebtRepayments()
						.then(function(response){
							deferred.resolve(response);
						}, function(){
							$state.go('private.app.dashboard.modal.affordability.confirm_expenses');
							deferred.reject();
						});
						
						return deferred.promise;
				}]
			}  
		})		
		.state('private.app.dashboard.modal.affordability.confirm_expenses', {   
			url:'/confirm_expenses',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('confirm_expenses', 'widgets/affordability'),		
					controller: 'AffordabilityConfirmExpensesController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				transactions: ['TransActTransactionFactory', function(TransActTransactionFactory){
					return TransActTransactionFactory.getExpenses();
				}]
			}  
		})
		.state('private.app.dashboard.modal.affordability.rent', {   
			url:'/rent',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('rent', 'widgets/affordability'),		
					controller: 'AffordabilityRentController',
					controllerAs: 'vm'
				}
			}
		})
		
		.state('private.app.dashboard.modal.affordability.review_income', {   
			url:'/review_income',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('review_income', 'widgets/affordability'),		
					controller: 'AffordabilityReviewIncomeController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				categories: ['TransActCategoriesFactory', function(TransActCategoriesFactory){
					return TransActCategoriesFactory.getAll(['I']);
				}],
				userProfile: function(UserFactory){
					return UserFactory.get();
				}	
			} 
		})		
		
		.state('private.app.dashboard.modal.affordability.review_deductions', {   
			url:'/review_deductions',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('review_deductions', 'widgets/affordability'),		
					controller: 'AffordabilityReviewDeductionsController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				categories: ['TransActCategoriesFactory', function(TransActCategoriesFactory){
					return TransActCategoriesFactory.getAll(['D']);
				}],
				userProfile: function(UserFactory){
					return UserFactory.get();
				}	
			} 
		})	
		
		.state('private.app.dashboard.modal.affordability.review_expenses', {   
			url:'/review_expenses',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('review_expenses', 'widgets/affordability'),		
					controller: 'AffordabilityReviewExpensesController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				categories: ['TransActCategoriesFactory', function(TransActCategoriesFactory){
					return TransActCategoriesFactory.getAll(['E']);
				}],
				userProfile: function(UserFactory){
					return UserFactory.get();
				}			
			} 
		})					
		
		.state('private.app.dashboard.modal.affordability.review_adjustments', {   
			url:'/review_adjustments',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('review_adjustments', 'widgets/affordability'),		
					controller: 'AffordabilityReviewAdjustmentsController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				categories: ['TransActCategoriesFactory', function(TransActCategoriesFactory){
					return TransActCategoriesFactory.getAll(['EA', 'ER', 'E']);
				}],
				userProfile: function(UserFactory){
					return UserFactory.get();
				},				
			} 
		})		
		.state('private.app.dashboard.modal.affordability.review_summary', {   
			url:'/review_summary?recalculate',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('review_summary', 'widgets/affordability'),		
					controller: 'AffordabilityReviewSummaryController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				categories: ['TransActCategoriesFactory', function(TransActCategoriesFactory){
					return TransActCategoriesFactory.getAll(['EA', 'ER']);
				}],
				userProfile: function(UserFactory){
					return UserFactory.get();
				},				
			} 
		})	
		.state('private.app.dashboard.modal.affordability.failed_affordability', { 
			url:'/failed_affordability',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('failed_affordability', 'widgets/affordability'),
					controller: 'AffordabilityFailedAffordabilityController',
					controllerAs: 'vm'					
				}
			} 
		})				
		/*.state('private.app.dashboard.modal.affordability.money_summary', {   
			url:'/money_summary',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('money_summary', 'widgets/affordability'),		
					controller: 'AffordabilityMoneySummaryController',
					controllerAs: 'vm'
				}
			},
			resolve:{
				pdfLink: ['MoneySummaryHtmlFactory', function(MoneySummaryHtmlFactory){
					return MoneySummaryHtmlFactory.getLink();
				}]
			}  
		})*/
		
		.state('private.app.dashboard.modal.certificate', {
			abstract: true
		})	
		.state('private.app.dashboard.modal.certificate.index', {   
			url:'/index',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('index', 'widgets/certificate'),
					controller: 'CertificateIndexController',
					controllerAs: 'vm'				
				}
			},
			resolve: {
				certificates: ['CertificateFactory', function(CertificateFactory){
					return CertificateFactory.getAll();
				}],
				invites: ['CertificateFactory', function(CertificateFactory){
					return CertificateFactory.getInvites();
				}]
			}     
		})	
		.state('private.app.dashboard.modal.certificate.claim', {   
			url:'/index',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('claim', 'widgets/certificate'),
					controller: 'CertificateClaimController',
					controllerAs: 'vm'				
				}
			}    
		})		
		.state('private.app.dashboard.modal.certificate.invite', {   
			url:'/invite?certificate_id',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('invite', 'widgets/certificate'),
					controller: 'CertificateInviteController',
					controllerAs: 'vm'				
				}
			},
			resolve: {
				certificate: ['$stateParams', 'CertificateFactory', function($stateParams, CertificateFactory){
					return CertificateFactory.get($stateParams.certificate_id);
				}]
			}     
		})
		.state('private.app.dashboard.modal.certificate.invited', {   
			url:'/invited',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('invited', 'widgets/certificate')
				}
			}     
		})				
		.state('private.app.dashboard.modal.certificate.quote', {   
			url:'/quote?certificate_id',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('quote', 'widgets/certificate'),
					controller: 'CertificateQuoteController',
					controllerAs: 'vm'				
				}
			},
			resolve: {
				quote: ['$stateParams', 'CertificateFactory', function($stateParams, CertificateFactory){
					return CertificateFactory.getHtml($stateParams.certificate_id);
				}]
			}     
		})
		
		.state('private.app.dashboard.modal.property', {
			abstract: true,
			url:'/property'			
		})		
		.state('private.app.dashboard.modal.property.start', {   
			url:'/start',			
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('start', 'widgets/property'),
					controller: 'PropertyStartController',
					controllerAs: 'vm'
				}
			},
			resolve:{
			
			}  
		})		
		.state('private.app.dashboard.modal.property.price', { 
			url:'/price',			  
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('price', 'widgets/property'),
					controller: 'PropertyPriceController',
					controllerAs: 'vm'				
				}
			},
			resolve:{
				certificates: ['CertificateFactory', function(CertificateFactory){
					return CertificateFactory.getAll();
				}]				
			}
		})
		.state('private.app.dashboard.modal.property.location', {   
			url:'/location',			
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('location', 'widgets/property'),
					controller: 'PropertyLocationController',
					controllerAs: 'vm'				
				}
			},
			resolve: {
				provinces: ['ProvinceFactory', function(ProvinceFactory){
					return ProvinceFactory.getAll();
				}],
				property_interest: ['PropertyInterestFactory', '$q', function(PropertyInterestFactory, $q){
					var deferred = $q.defer();
						PropertyInterestFactory.get()
							.then(function(response){
								deferred.resolve(response);
							}, function(){
								deferred.resolve({});								
							});
					return deferred.promise;
				}]				
			}
		})
		.state('private.app.dashboard.modal.property.type', {   
			url: '/type',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('type', 'widgets/property'),
					controller: 'PropertyTypeController',
					controllerAs: 'vm'				
				}
			},
			resolve:{
				propertyTypes: function(PropertyTypeFactory){
					return PropertyTypeFactory.getAll();
				}
			}
		})
		.state('private.app.dashboard.modal.property.feature', {   
			url: '/feature',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('feature', 'widgets/property'),
					controller: 'PropertyFeatureController',
					controllerAs: 'vm'				
				}
			}
		})
		
		.state('private.app.dashboard.modal.property.current', {   
			url: '/current',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('current', 'widgets/property'),
					controller: 'PropertyCurrentController',
					controllerAs: 'vm'				
				}
			}
		})	
		
		.state('private.app.dashboard.modal.property.success', {   
			url: '/success',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('success', 'widgets/property'),
					controller: 'PropertySuccessController',
					controllerAs: 'vm'				
				}
			}
		})		
		
		.state('private.app.dashboard.modal.property.failed', {   
			url: '/failed',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('failed', 'widgets/property'),
					controller: 'PropertyFailedController',
					controllerAs: 'vm'				
				}
			}
		})				
		
		.state('private.app.dashboard.modal.fica', {
			abstract: true,
			url: '/fica'
		})		
		
		.state('private.app.dashboard.modal.fica.complete', { 
			url: '/complete',
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('complete', 'widgets/fica'),
					controller: 'FicaCompleteController',
					controllerAs: 'vm'					
				}
			}, 
			
			resolve: {
				manual:['CreditCrunchAuth', function(CreditCrunchAuth){
					return CreditCrunchAuth.getManualMail();
				}]
			}
		})
		
		.state('private.app.dashboard.modal.fica.upload', { 
			url: '/upload',  
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('upload', 'widgets/fica'),
					controller: 'FicaUploadController',
					controllerAs: 'vm'				
				}
			},
			resolve:{
				user:['UserFactory', function(UserFactory){
					return UserFactory.get();
				}]
			}
		})
		
		.state('private.app.dashboard.modal.otp', {
			abstract: true,		
			url: '/mobile'		
		})
		.state('private.app.dashboard.modal.otp.mobile', {  
			url: '/number',			
			views:{
				'content@private.app.dashboard.modal':{
					templateUrl: getView('mobile', 'widgets/otp'),
					controller: 'OtpMobileController',
					controllerAs: 'vm'	
				}
			}
		})
		.state('private.app.dashboard.modal.otp.pin', {   
			url: '/otp',			
			views:{
				'content@private.app.dashboard.modal':{
					templateUrl: getView('pin', 'widgets/otp'),
					controller: 'OtpPinController',
					controllerAs: 'vm'	
				}
			}
		})
		
		.state('private.app.dashboard.modal.otp.limit', { 
			url:'/limit', 
			views:{
				'content@private.app.dashboard.modal' : {
					templateUrl: getView('limit', 'widgets/otp'),
					controller: 'OtpLimitController',
					controllerAs: 'vm'					
				}
			} 
		})		
		
		.state('logout', {
			url: '/logout',
			controller: function($auth, $state, $sessionStorage, IntercomFactory){
				$sessionStorage.$reset(); 
				IntercomFactory.logout();
				$auth.logout();
				$state.go('public.login');
			},
			controllerAs: 'vm'	
		});			
	});
})();
