(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityDebtRepaymentsController', AffordabilityDebtRepaymentsController);
	
	function AffordabilityDebtRepaymentsController(_, API, $state, $log, debt_repayments){
		var vm = this;
		vm.debt_repayments = debt_repayments;
		vm.total = 0;
		
		if(!_.isEmpty(debt_repayments)){
			_.each(debt_repayments, function(d, key){
				vm.total += Math.abs(d.instalment_amount);
	    });		
    }
	}
})();