//==============================================
// Branding Factory
//==============================================

(function() {
	"use strict";

	angular.module('app.services').factory('BrandingFactory', function(API) {
		var factory = {};
		var prefix = 'branding';
		
		factory.get = function(){
			return API.one(prefix).get();
		}
		
		return factory;
	});
})();
