(function() {
	"use strict";

	angular.module('app.controllers').controller('PropertyFeatureController', PropertyFeatureController);
	
	function PropertyFeatureController(_, API, user, $state, UserFactory, PropertyInterestFactory, IntercomFactory, property_interest){
		var vm = this;
		vm.data = {};
		vm.submit = submit;
		
		vm.data = property_interest;
		
		function submit(){			
			var data = {features: vm.data.features};

			PropertyInterestFactory.add(data)
				.then(function(){	
						$state.go('private.app.dashboard.modal.property.current');
				});
		}
	}
})();