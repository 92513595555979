(function (){
	"use strict";

	angular.module('app.config').config(function($intercomProvider) {
    // Either include your app_id here or later on boot

    // you can include the Intercom's script yourself or use the built in async loading feature
    $intercomProvider
      .asyncLoading(false)
  })
})();
