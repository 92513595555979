(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityConfirmIncomeController', AffordabilityConfirmIncomeController);
	
	function AffordabilityConfirmIncomeController(_, API, $state, $log, TransActTransactionFactory, transactions, categories, IntercomFactory){
		var vm = this;
		vm.transactions = transactions;
		vm.categories = categories;
		vm.data = {};
		vm.data.Transactions = {};
		vm.data.TransactionCategories = {};
		    
		angular.element('.modal-dialog').addClass('w-900');
		
		_.each(transactions, function(t, key){
	    	var state = false;
	    	if(t.score > 0){
	    		state = true;
	    	}

				vm.data.TransactionCategories[t._id] = t.user_category.id;
	    	vm.data.Transactions[t._id] = state;
	    	vm.total = calcTotal();
	    });
 
	    vm.checkTransaction = function(){	    	
	    	vm.total = calcTotal();
	    	return vm.total;
	    }
	    
	    function calcTotal(){
	    	var total = 0;
	    	_.each(vm.data.Transactions, function(state, tid){
	    		
	    		if(state === true){	    			
	    			total += Math.abs(getTransactionAmount(tid));
	    		}
		    });
	    	
	    	return total;
	    }
	    
	    function getTransactionAmount(tid){
	    	var amount = 0;
	    	_.each(transactions, function(t, key){
	    		if(tid == t._id){
	    			amount = t.amount;
	    		}
	    	});
	    	
	    	return amount;
	    }
	
		vm.submit = function(){	    	
	    	TransActTransactionFactory.postIncome({Transactions: vm.data.Transactions})
	    		.then(function(income){		
		    		
		    		TransActTransactionFactory.postCategorize({Transactions: vm.data.TransactionCategories})
		    			.then(function(){
								IntercomFactory.trackEvent('Affordability_Confirmed_Income');	
								$state.go('private.app.dashboard.modal.affordability.gross_income');			    			
		    			});
		    		    		
		    		IntercomFactory.trackEvent('Affordability_Confirmed_Income');	
						$state.go('private.app.dashboard.modal.affordability.gross_income');
	    		}, function(error){
	    			$state.go('private.app.dashboard.modal.affordability.progress');
	    		});
		}
	}
})();