//==============================================
// Property Type Service
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('PropertyTypeFactory', function($q) {
		var factory = {};
		var types = [
			'Freestanding House',
			'Flat or Apartment',
			'Townhouse or Cluster',
			'Farm or Smallholding',
			'Land'
		];
		
		factory.getAll = function(){
			var deferred = $q.defer();			
			deferred.resolve(types);
			return deferred.promise;
		}
		
		return factory;
	});
})();