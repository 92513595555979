(function() {
	"use strict";

	angular.module('app.controllers').controller('PropertyWidgetSummaryController', PropertyWidgetSummaryController);
	
	function PropertyWidgetSummaryController(_, user, property_interest, concierge_certificate_count){
		var vm = this;
		
		vm.user = user;
		vm.property_interest = property_interest;
		vm.concierge_certificate_count = concierge_certificate_count;
	}	
})();
