(function() {
	"use strict";

	angular.module('app.controllers').controller('ProfileWidgetSummaryController', ProfileWidgetSummaryController);
	
	function ProfileWidgetSummaryController($log, user){
		var vm = this;
		vm.user = user;		
	}	
})();
