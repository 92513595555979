(function() {
	"use strict";

	angular.module('app.controllers').controller('OtpPinController', OtpPinController);
	
	function OtpPinController(_, API, $state, $timeout, SecurityOtp, ToastService, IntercomFactory){
		var vm = this;
		vm.data = {};
		vm.error = {};
		vm.submit = submit;
		vm.resend = resend;
		vm.timer = '';
		vm.allow_resend = false;
		
		function submit(){			
			var code = vm.data.code;
			SecurityOtp.getConfirm(code)
				.then(function(response){	
					if(response.Verified){
						IntercomFactory.trackEvent('OTP_Confirmed');
						$state.go('private.app.dashboard.modal.verify.start', {}, {reload: true});	
					}
				}, function(){
						ToastService.error('Your OTP was incorrect. Please try again.'); 
				});
		}
		
		function resend(){
			if(vm.allow_resend){
				$state.go('private.app.dashboard', {}, {reload: true});				
			}			
		}
		
		function timer(remaining) {
		  var m = Math.floor(remaining / 60);
		  var s = remaining % 60;
		  
		  m = m < 10 ? '0' + m : m;
		  s = s < 10 ? '0' + s : s;
		  vm.timer = m + ':' + s;
		  remaining -= 1;
		  
		  if(remaining >= 0) {
		    $timeout(function() {
		        timer(remaining);
		    }, 1000);
		    return;
		  }
		  
		  // Do timeout stuff here
		  vm.allow_resend = true;
		}		
		
		timer(10);
	}
})();