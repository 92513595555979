//==============================================
// Event Factory
//==============================================

(function() {
	"use strict";

	angular.module('app.services').factory('EventFactory', function(API) {
		var factory = {};
		var prefix = 'events/trigger';
		
		factory.trigger = function(event){
			return API.one(prefix+'/'+event).get();
		}
		
		return factory;
	});
})();
