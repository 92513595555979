(function() {
	"use strict";

	angular.module('app.controllers').controller('OtpMobileController', OtpMobileController);
	
	function OtpMobileController(_, API, $state, $sessionStorage, SecurityOtp, IntercomFactory){
		var vm = this;
		vm.data = {};
		vm.submit = submit;		
		
		if(!$sessionStorage.hasOwnProperty('otp_count')){
			$sessionStorage.otp_count = 0;			
		}
		function submit(){			
			var mobile = vm.data.mobile;
			SecurityOtp.getSend(mobile)
				.then(function(){	
					$sessionStorage.otp_count++;					
					IntercomFactory.trackEvent('OTP_Sent');
					$state.go('private.app.dashboard.modal.otp.pin');
				});
		}
		angular.element('.modal-dialog').removeClass('w-900');
	}
})();