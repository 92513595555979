(function (){
	"use strict";

	angular.module('app.config').config(function($httpProvider) {
		$httpProvider.interceptors.push(['$injector', '$q', function interceptors($injector, $q) {
		return {
	        // preventing duplicate requests
	        request: function request(config) {
							var $timeout = $injector.get('$timeout');		        
							var cfpLoadingBar = $injector.get('cfpLoadingBar');
									        							
							var t = $injector.get('ToastService');		        
	            var $http = $injector.get('$http');
	            var _config = angular.copy(config);
	            delete _config.headers;
	
	            function isConfigEqual(pendingRequestConfig) {
		            if(pendingRequestConfig.url.indexOf('.html') > 0){
			            return false;
		            }
                var _pendingRequestConfig = angular.copy(pendingRequestConfig);
                delete _pendingRequestConfig.headers;

                return angular.equals(_config, _pendingRequestConfig);
	            }
	
	            if ($http.pendingRequests.some(isConfigEqual)) {
								t.success('Your request is processing, please wait...');	
								$timeout(function(){
									cfpLoadingBar.complete();
								}, 2000);
                return $q.reject(request);
	            }
	
	            return config;
	        }
	    };
		}
		]);
  })
})();




/*
	
	(function (){
	"use strict";

	angular.module('app.config').config(function($httpProvider) {
		$httpProvider.interceptors.push(['$injector', '$q', function interceptors($injector, $q) {
		    return {
		        // preventing duplicate requests
		        request: function request(config) {
			        var request = this;

								var t = $injector.get('ToastService');
		            var $http = $injector.get('$http');
		            var _config = angular.copy(config);
		            delete _config.headers;
		
								var index = null;
		            function isConfigEqual(pendingRequestConfig, i) {
			            if(pendingRequestConfig.url.indexOf('.html') > 0){
				            return false;
			            }
		            	index = i;
	                var _pendingRequestConfig = angular.copy(pendingRequestConfig);
	                delete _pendingRequestConfig.headers;
	
	                if(angular.equals(_config, _pendingRequestConfig)){
		                return true;
	                }else{
		                return false;
	                }
		            }
		
		            if ($http.pendingRequests.some(isConfigEqual)) {
			            console.log($http.pendingRequests);
				            t.success('Your request is processing, please wait...');
				            var r = angular.copy($http.pendingRequests[index]);
				            r.config = _config;
				            r.headers = null;
				            console.log(r);
		                delete $http.pendingRequests[index];				            
		                return $q.reject(r); 

		            }
		
		            return config;
		        }
		    };
		}
		]);
  })
})();


	
*/

