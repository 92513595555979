(function() {
	"use strict";

	angular.module('app.controllers').controller('CertificateInviteController', CertificateInviteController);
	
	function CertificateInviteController(_, API, $state, $stateParams, CertificateFactory, certificate, IntercomFactory){
		var vm = this;
		var certificate_id = $stateParams.certificate_id;
		vm.certificate = certificate;
		vm.data = {};
		vm.submit = submit;
		vm.resend = resend;
		
		function submit(){			
			var mobile = vm.data.mobile;
			CertificateFactory.postInvite(certificate_id, vm.data)
				.then(function(response){	
					if(response.sent){
						IntercomFactory.trackEvent('Dual_Loan_Invited');
						$state.go('private.app.dashboard.modal.certificate.invited');	
					}
				});
		}
		
		function resend(){
			CertificateFactory.postInvite(certificate_id, {mobile: certificate.invited})
				.then(function(response){	
					if(response.sent){
						$state.go('private.app.dashboard.modal.certificate.invited');	
					}
				});
		}
	}
})();