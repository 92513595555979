(function() {
	"use strict";

	angular.module('app.controllers').controller('CertificateIndexController', CertificateIndexController);
	
	function CertificateIndexController($state, $window, certificates, CertificateFactory, invites){
		var vm = this;
		vm.invites = invites;
		vm.certificates = certificates;
		
		vm.goto = function(certificate){			 
			$state.go('private.app.dashboard.modal.certificate.quote', {certificate_id: certificate._id})
		}
		
		vm.invite = function(certificate_id){
			$state.go('private.app.dashboard.modal.certificate.invite', {certificate_id: certificate_id});
		}
		
		vm.pdf = function(certificate){
			var pdf = CertificateFactory.getPdfLink(certificate._id);
			$window.open(pdf);
			$state.go("private.app.dashboard", {}, {reload: true});			
		}
	}	
})();
