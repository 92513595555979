(function() {
	"use strict";

	angular.module('app.controllers').controller('PropertyTypeController', PropertyTypeController);
	
	function PropertyTypeController(_, API, user, $state, UserFactory, propertyTypes, PropertyInterestFactory, IntercomFactory, property_interest){
		var vm = this;
		vm.propertyTypes = propertyTypes;
		vm.data = {};
		vm.submit = submit;
		vm.isEmptyPropertyTypes = true;
		
		if(!_.isEmpty(property_interest)){			
			if(!_.isEmpty(property_interest.property_type)){
				vm.data.propertyTypes = property_interest.property_type;
			}
		}

		vm.validatePropertyTypes = function(){
			var isEmpty = true;
			
			_.each(vm.data.propertyTypes, function(val, key){
				if(typeof val != 'undefined'){
					isEmpty = false;
				}
			});
			vm.isEmptyPropertyTypes = isEmpty;
		}
		
		function submit(){			
			var data = {property_type: vm.data.propertyTypes};

			PropertyInterestFactory.add(data)
				.then(function(){	
						IntercomFactory.trackEvent('PropertyInterest_Confirmed_Type');	
						$state.go('private.app.dashboard.modal.property.feature');
				});
		}
		vm.validatePropertyTypes();
	}
})();