(function() {
	"use strict";

	angular.module('app.controllers').controller('LoginController', LoginController);

	function LoginController($log, $auth, $state, ToastService, $sessionStorage) {	
		var vm = this;
		
		vm.login = login;
		
		function login(){
			var user = {
					email: vm.email,
					password: vm.password
			};
			
			if(! _.isEmpty($sessionStorage.tracking_params)){
				user.tracking_parameters = $sessionStorage.tracking_params;
			}						
			
			$auth.login(user)
				.then(function(response){
					$log.info(response);
					$state.go('private.app.dashboard');
				}, loginFail);
		}
		
		function loginFail(response){
			$log.error(response);
			ToastService.error('Incorrect Login Details'); 
		}
		
	}

})();
