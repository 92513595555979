//==============================================
// Event Factory
//==============================================

(function() {
	"use strict";

	angular.module('app.services').factory('LeadFactory', function(API) {
		var factory = {};
		var prefix = 'leads/send';
		
		factory.send = function(){
			return API.one(prefix).get();
		}
		
		return factory;
	});
})();
