(function() {
	"use strict";

	angular.module('app.controllers').controller('DashboardController', DashboardController);
	
	function DashboardController(_, user, snapshot, affordability, $state, $timeout, $localStorage, $sessionStorage, UserFactory, IntercomFactory, $window){
		var vm = this;
		vm.snapshot = snapshot;
		vm.affordability = affordability;
		var storage = $localStorage;
		vm.user = user;
		
		vm.widgets = {};
		vm.widgets.Fica = {
			status: true
		}
		
		vm.storage = storage;
		
		//We forcing hiding it
		storage.hideJourneyCover = true;
		
		vm.closeJourneyCover = function(){
			storage.hideJourneyCover = true;
			angular.element('#journey-cover').addClass('bounceOutUp');
		}
		
		if(storage.hasOwnProperty('hideJourneyCover') == false){
			$timeout(function(){
				angular.element('#journey-cover').addClass('swing');
			}, 3000);	
		}
		
		console.log('checking');
		if(_.isEmpty(affordability) && _.isEmpty(snapshot)){
			console.log('Empty');
			// if mobile is empty
			if(_.isEmpty(user.mobile)){
				if($sessionStorage.hasOwnProperty('otp_count')){
					console.log('OTP Count is ' + $sessionStorage.otp_count);						
					if($sessionStorage.otp_count >= 3){							
						console.log('OTP Limit reached');	
						$state.go('private.app.dashboard.modal.otp.limit');							
					}else{						
						$state.go('private.app.dashboard.modal.otp.mobile');	
					}
				}else{
					$state.go('private.app.dashboard.modal.checklist');						
				}
			}else{
				console.log('Checking if Affordability, Snapshot & mobile exist');
				IntercomFactory.trackEvent('Registered', {});
				$state.go('private.app.dashboard.modal.verify.start');				
			}
						
			return;
		}		
		

		
				
		
		if(_.isEmpty(snapshot)){
			console.log('Checking if Snapshot exists');
			//$state.go('private.app.dashboard.modal.verify.start');
		}					
	}
	
})();
