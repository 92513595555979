(function() {
	"use strict";

	angular.module('app.controllers').controller('ProfileAddressController', ProfileCompleteController);
	
	function ProfileCompleteController(API, $state, $timeout, $log, $scope, UserFactory, IntercomFactory, provinces, user){
		var vm = this;
		vm.provinces = provinces;
		vm.submit = submit;
		vm.address = {
				lat: '', long: '', street_number: '', street_name: '', suburb: '', city: '', country: '', postal_code: ''
			}		
		
		vm.autocompleteOptions = {
			componentRestrictions: { country: 'za' },
      types: ['geocode']
		};
		
		if(user.profile != null){
			if(user.profile.hasOwnProperty('home_address')){
				vm.address = user.profile.home_address;	
			}
		}
		
		$scope.$on('g-places-autocomplete:select', function (event, data) {
			vm.address.lat = data.geometry.location.lat();
			vm.address.long = data.geometry.location.lng();
			
			angular.forEach(data.address_components, function(component) {
				angular.forEach(component.types, function(type){	            		    	  
					if(type == 'street_number'){
						vm.address.street_number = component.long_name;
						return;
					}
					
					if(type == 'route'){
						vm.address.street_name = component.long_name;
						return;
					}
									
					if(type == 'sublocality_level_2'){
						vm.address.suburb = component.long_name;
						return;
					}

					if(type == 'sublocality_level_1'){
						if(vm.address.suburb == ''){
							vm.address.suburb = component.long_name;
							return;
						}
					}

					if(type == 'locality'){
						vm.address.city = component.long_name;
						return;
					}       

					if(type == 'administrative_area_level_1'){
						vm.address.province = component.long_name;
					}

					if(type == 'country'){
						vm.address.country = component.long_name;
						return;
					}

					if(type == 'postal_code'){
						vm.address.postal_code = component.long_name;
						return;
					} 		    	                    	    	  
				});	    	    
			}); 
			
			$timeout(function(){
				angular.element('.form-control[name="street_name"]').trigger('change');
				angular.element('.form-control[name="street_number"]').trigger('change');
				angular.element('.form-control[name="suburb"]').trigger('change');
				angular.element('.form-control[name="city"]').trigger('change');
				angular.element('.form-control[name="province"]').trigger('change');
				angular.element('.form-control[name="postal_code"]').trigger('change');
			});
		});
		
		function submit(){			
			var data = vm.address;

			UserFactory.postProfile({home_address: data})
				.then(function(response){	
						$log.info(response);			
						$state.go('private.app.dashboard.modal.profile.personal');			
				});
		}
		
		IntercomFactory.trackEvent('Profile_Started');	
	}
	
})();
