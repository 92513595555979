(function() {
	"use strict";

	angular.module('app.controllers').controller('PropertySuccessController', PropertySuccessController);
	
	function PropertySuccessController(_, API, $state){
		var vm = this;
	//	cfpLoadingBar.start();
		vm.submit = submit;
		
    function submit(){
			$state.go("private.app.dashboard", {}, {reload: true});
    }		
	}
	
})();
