(function() {
	"use strict";

	angular.module('app.controllers').controller('VerifyWidgetSummaryController', VerifyWidgetSummaryController);
	
	function VerifyWidgetSummaryController(_, snapshot){
		var vm = this;
		vm.snapshot = snapshot;
		
		vm.scoreGraph = {
			options: {
				circleColor: '#7DDF1E',
				textColor: '#7DDF1E',
				waveTextColor: '#FFFFFF',
				waveColor: '#7DDF1E',
				displayPercent: false	,
				minValue: 0,
				maxValue: 1000		
			},
			value: 0
		};
			
			if(!_.isEmpty(snapshot)){
				vm.scoreGraph.options.maxValue = snapshot.max_score;
				vm.scoreGraph.value = snapshot.score;
			}
	}	
})();
