//==============================================
// Property Interest Factory
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('PropertyInterestFactory', function(API) {
		var factory = {};
		var prefix = 'property';
		
		factory.add = function(data, submit){
			return API.all(prefix + '/interest').post({'PropertyInterest' : data, 'submit': submit});
		}
		
		factory.submit = function(data){
			return API.all(prefix + '/interest').post({'PropertyInterest' : data, 'submit': submit});
		}		
		
		factory.get = function(){
			return API.one(prefix + '/interest').get();
		}
		
		return factory;
	});
})();