//==============================================
// Auth Service
//==============================================

(function() {
	"use strict";

	angular.module('app.services').factory('AuthFactory', function(API) {
		var factory = {};
		var prefix = 'auth';
		
		factory.emailForgotPassword = function(data){
			return API.all(prefix + '/password/email').post(data);
		}
		
		factory.validatePasswordResetToken = function(data){
			return API.all(prefix + '/password/verify').post(data);			
		}
		
		factory.resetPassword = function(data){
			return API.all(prefix + '/password/reset').post(data);			
		}
		
		return factory;
	});
})();
