(function() {
	"use strict";

	angular.module('app.controllers').controller('CertificateQuoteController', CertificateQuoteController);
	
	function CertificateQuoteController(_, API, $sce, quote){
		var vm = this;
		angular.element('.modal-dialog').addClass('w-900');
		vm.quoteHtml = $sce.trustAsHtml(quote);
	}
})();