//==============================================
// Property Interest Factory
//==============================================

(function() {
	"use strict";

	angular.module("app.services").factory('PropertyLocationFactory', function(API) {
		var factory = {};
		var prefix = 'property/location';
		
		factory.searchProvince = function(val){
			return API.all(prefix + '/province/'+val).getList();
		}
		
		factory.searchSuburb = function(province_id, val){
			return API.all(prefix + '/suburb/'+province_id+'/'+val).getList();
		}		
		
		return factory;
	});
})();