(function(){
    "use strict";

    angular.module('app.controllers').controller('FooterController', FooterController);

    function FooterController(){
        var vm = this;
        var today = new Date();
        vm.year = today.getFullYear();
    }

})();
