(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityLoginController', AffordabilityLoginController);
	
	function AffordabilityLoginController(_, $state, $stateParams, $log, API, TransActAccountFactory, IntercomFactory, banks, site){
		var site_id = null;
		var vm = this;
		vm.selectedBank = null;
		vm.data = {LoginForm:null};	
		
		function initializeFields(){
			var placeHolders = {
				15393: {
					'LOGIN': {title: 'Access account number'},
					'PASSWORD': {title: 'PIN'},
					'LOGIN1': {title: 'User Number', 'value': '1'},
					'OP_PASSWORD1': {title: 'Password'}												
				}
			};
			angular.forEach(banks, function(bank, key) {
				angular.forEach(placeHolders, function(newFields, site_id){
					if(bank.site_id == site_id){
						
						angular.forEach(bank.login_fields, function(field){
							angular.forEach(newFields, function(newFieldProperties, newField){
								if(field.name == newField){
									angular.forEach(newFieldProperties, function(fieldValue, fieldProperty){
										field[fieldProperty] = fieldValue;
										
										if(fieldProperty == 'value'){
											vm.data.LoginForm = {LOGIN1: 1};
										}
									})
									console.log(field);
								}
							});
						});
					}
				})
	 		}); 
 		}
		
		vm.banks = banks;
			
		vm.getBankFields = getBankFields;
		vm.submit = submit;

		if(site != null){
			vm.site = site;
			getBankFields(site.bank_id);
		}
		if($stateParams.error_code != null && $stateParams.error_msg){
	    vm.error = {code: $stateParams.error_code, msg: $stateParams.error_msg};
    }
    
    vm.resetBankFields = function(){
			$state.go('private.app.dashboard.modal.affordability.login', {site_id: undefined, error_code: undefined, error_msg: undefined}, {reload: true});
    }
		
		function getBankFields(bank_id){
			vm.data = {};
			initializeFields();
			var bank = _.findWhere(vm.banks, {bank_id : bank_id})
			vm.selectedBank = bank;
			vm.bank = bank;
		}
		
		function submit(){
			TransActAccountFactory.postLogin(vm.data, site_id)
				.then(function(site){
					IntercomFactory.trackEvent('Affordability_Submitted_Credentials');	
					$state.go('private.app.dashboard.modal.affordability.progress', { site_id: site._id})            
				}, function(err){
					$state.go($state.current, {}, {reload: true});
				});
		}
	}
})();