(function() {
	"use strict";

	angular.module('app.controllers').controller('FicaCompleteController', FicaCompleteController);
	
	function FicaCompleteController(_, API, $state, user, IntercomFactory){
		var vm = this;
		
		vm.submit = function(){
			$state.go('logout', {}, {reload: true});
		}
	}
	
})();
