(function() {
	"use strict";

	angular.module('finlayer').factory('BankStatementStatementFactory', function(API, $q) {
		var factory = {};
		var prefix = 'finlayer/bank_statement/statement';
		
		factory.postParse = function(data){
			return API.all(prefix + '/parse').post(data);
		}
		
		return factory;
	});
})();
