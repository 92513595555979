(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityReviewAdjustmentsController', AffordabilityReviewAdjustmentsController);
	
	function AffordabilityReviewAdjustmentsController(_, API, $scope, $state, categories, userProfile, UserFactory, IntercomFactory, $locale, EventFactory){
		var vm = this;
		var user = userProfile;
		//hack
		$locale.NUMBER_FORMATS.CURRENCY_SYM = 'R';
		
		vm.categories = categories;
		vm.addExpenses = {};
		vm.remExpenses = {};		
		vm.netSurplus = 0;
		vm.total = 0;		
		
		var totalIncome = 0;
		var totalDeductions = 0;
		var totalExpenses = 0;												

		if(!_.isEmpty(user.profile)){
			if(!_.isEmpty(user.profile.affordability_categories)){				
				if(!_.isEmpty(user.profile.affordability_categories.E)){
					var catsExpenses = user.profile.affordability_categories.E;
				
					_.each(catsExpenses, function(total, category_id){
						totalExpenses += total;
					});
					
					_.each(categories, function(category, i){						
						if(category.type == 'ER'){
							var max_amount = 0;

							var cat = _.findWhere(categories, {ooba_key: category.ooba_key, type: 'E'});
							//get category object
							if(typeof catsExpenses[cat._id] != 'undefined'){
								max_amount = catsExpenses[cat._id];
							}

							_.extend(category, {max_amount: max_amount});
						}
					});						
					
				}								
				
				if(!_.isEmpty(user.profile.affordability_categories.I)){
					var cats = user.profile.affordability_categories.I;
				
					_.each(cats, function(total, category_id){
						totalIncome += total;
					});
				}			
				
				if(!_.isEmpty(user.profile.affordability_categories.D)){
					var cats = user.profile.affordability_categories.D;
				
					_.each(cats, function(total, category_id){
						totalDeductions += total;
					});
				}
				
				if(!_.isEmpty(user.profile.affordability_categories.EA)){
					var cats = user.profile.affordability_categories.EA;
				
					_.each(cats, function(total, category_id){												
						vm.addExpenses[category_id] = total;
					});
				}
				console.log(categories);
				if(!_.isEmpty(user.profile.affordability_categories.ER)){
					var cats = user.profile.affordability_categories.ER;
				
					_.each(cats, function(total, category_id){
						vm.remExpenses[category_id] = total;
					});
				}														
				
			}
		}
		
		
		
		
		vm.submit = function(){
			var data = {};
			_.each(vm.addExpenses, function(total, cat){
				if(isNaN(total)){
					total = 0;
				}
				
				data[cat] = total;
			});

			UserFactory.postProfile({"affordability_categories": {"EA": data}})
				.then(function(){			
					var data = {};
					_.each(vm.remExpenses, function(total, cat){
						if(!isNaN(total)){
							data[cat] = total;
						}
					});
		
					UserFactory.postProfile({"affordability_categories": {"ER": data}})
						.then(function(){			
							IntercomFactory.trackEvent('Affordability_Confirmed_Adjustment_Expenses');			
							//EventFactory.trigger('AffordabilityCompleted');		
							$state.go('private.app.dashboard.modal.affordability.review_summary', {}, {reload: true});
						});					
				});
		}
		
		vm.calcTotal = function(){
			var total = 0;

			_.each(vm.addExpenses, function(amount){
				if(isNaN(amount)){
					amount = 0;
				}
				total += amount;
			});
			
			_.each(vm.remExpenses, function(amount, cid){
				if(isNaN(amount)){
					amount = 0;
				}
				total -= amount;
			});	

			return total;	
		}
		
		$scope.$watch('vm.addExpenses', function(newVal, oldVal){
		    vm.total = vm.calcTotal();
		    vm.netSurplus = totalIncome - totalDeductions - totalExpenses - vm.total;
		}, true);	
		
		$scope.$watch('vm.remExpenses', function(newVal, oldVal){
		    vm.total = vm.calcTotal();
		    vm.netSurplus = totalIncome - totalDeductions - totalExpenses - vm.total;
		}, true);			
	}
})();