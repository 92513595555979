(function() {
	"use strict";

	angular.module('app.controllers').controller('FicaUploadController', FicaUploadController);
	
	function FicaUploadController(_, API, $scope, $window, $state, $auth, $localStorage, $timeout, ShiftCtrlAssetFactory, IntercomFactory, assets, user){
		var vm = this;
		var token = $auth.getToken();
		
		vm.data = {};
		vm.uploadedTotal = 0;
		
		if(!_.isEmpty(user.profile)){
			if(user.profile.hasOwnProperty('failed_authentication')){
				if(user.profile.failed_authentication == true){
					vm.showLogout = true;
				}
			}
		}

		vm.dropzone = {
			id: {
				title: 'Copy of Id',
				icon: 'icon-upload-id',
				config: {
					maxFiles: 1,
					maxFilesize: 5,
					acceptedMimeTypes: 'image/tiff,image/png,image/gif,image/jpeg,image/bmp,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',					
					url: "/api/finlayer/shift_ctrl/asset/upload/id",
					headers: {"Authorization" : "Bearer " + token},
					addRemoveLinks: true,		
					dictRemoveFile: 'Replace File',
					init: function() { this.on("maxfilesexceeded", function(file) { this.removeAllFiles(); this.addFile(file); }); }																		
				},
				element: null,
				uploaded: false,
				asset_id: null
			},	
			residence: {
				title: 'Proof of Residence',
				icon: 'icon-upload-address',
				config: {
					maxFiles: 1,					
					maxFilesize: 5,
					acceptedMimeTypes: 'image/tiff,image/png,image/gif,image/jpeg,image/bmp,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
					url: "/api/finlayer/shift_ctrl/asset/upload/residence",
					headers: {"Authorization" : "Bearer " + token},
					addRemoveLinks: true,
					dictRemoveFile: 'Replace File',
					init: function() { this.on("maxfilesexceeded", function(file) { this.removeAllFiles(); this.addFile(file); }); }								
				},
				element: null,
				uploaded:false,
				asset_id: null		
			}
		};	
		
		/*$timeout(function(){
			var mockFile = { name: "Filename", size: 12345 };
			vm.dropzone.id.element.emit("addedfile", mockFile);
	
				ShiftCtrlAssetFactory.getThumbnail('id')
				.then(function(t){
					vm.dropzone.id.element.emit("thumbnail", mockFile, t);
					vm.dropzone.id.element.emit("complete", mockFile);					
//					mockFile.previewElement.querySelector("img").src = t;
				});

		}, 100);*/
		
		vm.dzUploadSuccess = function(file, response, e){		
			IntercomFactory.trackEvent('Documents_Uploaded_'+response.data.description);
			
			var thumb =  file.previewElement.querySelector("img").src;			
			if(! _.isEmpty(thumb)){
				ShiftCtrlAssetFactory.uploadRawThumbnail(response.data.description, thumb);				
			}else{
				ShiftCtrlAssetFactory.deleteThumbnail(response.data.description);				
			}
			
			ShiftCtrlAssetFactory.getAll()
			.then(function(assets){
				countUploads(assets);
			}, function(){
								
			});		
		}
		
		vm.dzError = function(file){
			$(file.previewElement).parent().find('.btn-download').hide();
		}
		
		vm.dzRemovedFile = function(file){
			vm.uploadedTotal = vm.uploadedTotal - 1;
			$scope.$apply();
		}
		
		vm.download = function(asset_id){
			var token = $auth.getToken();
			var url = '/api/finlayer/shift_ctrl/asset/download/'+asset_id+'?token='+token;
			window.open(url);
		}
		
		function countUploads(assets){
			vm.uploadedTotal = 0;
			_.each(assets, function(asset, key){
				var description = asset.description;
				if(description != ''){
					if(!_.isEmpty(vm.dropzone[description]) ){						
						vm.dropzone[description].uploaded = true;
						vm.dropzone[description].asset_id = asset._id;																								
					}				
				}
			});
			
			_.each(vm.dropzone, function(zone, key){
				if(zone.uploaded == true){
					vm.uploadedTotal++;
				}
			});
			
			if(_.isEmpty(assets)){
				IntercomFactory.trackEvent('Documents_Started');
			}
		}
		
		function initializeDropzoneUploads(assets){
			_.each(assets, function(asset, key){
				var description = asset.description;
				if(description != ''){
					if(!_.isEmpty(vm.dropzone[description]) ){						
											
						$timeout(function(){
						var mockFile = { name: asset.original_filename, size: asset.size, accepted: true };
						vm.dropzone[description].element.emit("addedfile", mockFile);
				
							ShiftCtrlAssetFactory.getThumbnail(description)
							.then(function(t){
								if(!_.isEmpty(t)){
									vm.dropzone[description].element.emit("thumbnail", mockFile, t);									
								}
								vm.dropzone[description].element.emit("complete", mockFile);
								vm.dropzone[description].element.files.push(mockFile);
							});
						}, 100);
						vm.dropzone[description].uploaded = true;	
						vm.dropzone[description].asset_id = asset._id;																
					}				
				}
			});
		}
		
		vm.submit = function(){
			if(vm.uploadedTotal == Object.keys(vm.dropzone).length){
				$state.go('private.app.dashboard.modal.fica.complete');
			}
		}
		
		vm.closeModal = function(){
			if(vm.uploadedTotal >= 2){
				$state.go("private.app.dashboard", {}, {reload: true});
			}
		}
		
		initializeDropzoneUploads(assets);
		countUploads(assets);
	}
})();