(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityProgressController', AffordabilityProgressController);
	
	function AffordabilityProgressController(_, API, $state, $log, $stateParams, $timeout, TransActAccountFactory, user){
		var vm = this;
		vm.refreshState = {progress: 0};
		var site_id = null;
		var tries = 0;
		var max_tries = 200;
		var same_refresh_state_count = 0;
		var same_refresh_state_max = 40; //Half of actual attempts
		var last_refresh_state = null;
		var try_msg = 'There was a problem connecting to your bank account. Please try again.';
				
		vm.user = user;
		vm.timeout = null;

		if($stateParams.site_id != null){
			site_id = $stateParams.site_id;
			getSiteRefreshState(site_id);
		}else{
			getAllSitesRefreshState();
		}		
		
		
		function getAllSitesRefreshState(){
	    
	    var poll = function(){ 
		    TransActAccountFactory.getProgressAll()
		        .then(
		        function(data){
		            vm.refreshState = data.Summary;
								_.each(data.Sites, function(site, i){
	
									if(site.state.error_code != 0 || site.state.state == 'failed') {
										console.log(vm.timeout);
										$timeout.cancel(vm.timeout);
										$state.go('private.app.dashboard.modal.affordability.login', { site_id: site.site_id, error_code: site.state.error_code, error_msg: site.state.error_msg});
										return;
									}
			
									if(site.state.refresh_code == 'auth_required'){
										$state.go('private.app.dashboard.modal.affordability.mfa', { site_id: site.site_id}) 
										return;
									}
								});
		
		            if(data.Summary.progress >= 100 && data.Summary.error_code == 0){
										$state.go('private.app.dashboard.modal.affordability.accounts', {source: 'scrape'});
		            }else{              
			            
			            if(same_refresh_state_count < same_refresh_state_max){
					          if(last_refresh_state != data.Summary.progress){
											last_refresh_state = data.Summary.progress;
											same_refresh_state_count = 0;
										}else{
											same_refresh_state_count++;
										}  
			            }else{
				           	$timeout.cancel(vm.timeout);
		                $state.go('private.app.dashboard.modal.affordability.login', { site_id: null, error_code: -1, error_msg: try_msg});
		                return;
			            }
		            
		            	if(tries < max_tries){		     
				            tries++;
	 	                vm.timeout = $timeout(function(){ poll()}, 3000);
		            	}else{
		                $timeout.cancel(vm.timeout);
		                $state.go('private.app.dashboard.modal.affordability.login', { site_id: null, error_code: -1, error_msg: try_msg});
		                return;
		            	}
		            }
		        }, function(error){
		          $state.go('private.app.dashboard.modal.affordability.login');
		        }
		    );
	    }
	    
	    poll();
		}
		
    function getSiteRefreshState(site_id){
	    var poll = function(){ 
        TransActAccountFactory.getProgress(site_id)
            .then(
            function(data){
                vm.refreshState = data;

                if(data.error_code != 0 || data.state == 'failed') {
                    $timeout.cancel(vm.timeout);
                    $state.go('private.app.dashboard.modal.affordability.login', { site_id: site_id, error_code: data.error_code, error_msg: data.error_msg});
                    return;
                }
                
                if(data.refresh_code == 'auth_required'){
	                console.log('here');
	                $timeout.cancel(vm.timeout);
                	$state.go('private.app.dashboard.modal.affordability.mfa', { site_id: site_id}) 
			    				return;
			    			}

                if(data.progress >= 100 && data.error_code == 0){
                    $timeout.cancel(vm.timeout);
                    $state.go('private.app.dashboard.modal.affordability.accounts', {source: 'scrape'});
                }else{                    
	                
	              if(same_refresh_state_count < same_refresh_state_max){
				          if(last_refresh_state != data.progress){
										last_refresh_state = data.progress;
										same_refresh_state_count = 0;
									}else{
										same_refresh_state_count++;
									} 
		            }else{
			           	$timeout.cancel(vm.timeout);
	                $state.go('private.app.dashboard.modal.affordability.login', { site_id: null, error_code: -1, error_msg: try_msg});
	                return;
		            }
	                
									if(tries < max_tries){					
				            	tries++;
	                    vm.timeout = $timeout(function(){ poll(site_id)}, 3000);    
		            	}else{
		                $timeout.cancel(vm.timeout);
		                $state.go('private.app.dashboard.modal.affordability.login', { site_id: site_id, error_code: -1, error_msg: try_msg});
		            	}
                }
            }, function(response){
							$state.go('private.app.dashboard.modal.affordability.login');
            }
        )
    	}
    	
    	poll();
    }
	}
})();