(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityReviewSummaryController', AffordabilityReviewSummaryController);
	
	function AffordabilityReviewSummaryController(_, API, $scope, $state, $stateParams, categories, userProfile, UserFactory, IntercomFactory, $locale, EventFactory){
		var vm = this;
		var user = userProfile;
		//hack
		$locale.NUMBER_FORMATS.CURRENCY_SYM = 'R';
		
		vm.categories = categories;
		
		vm.totalIncome = 0;
		vm.totalDeductions = 0;
		vm.totalExpenses = 0;					
		vm.totalAddExpenses = 0;
		vm.totalRemExpenses = 0;
		
		if(!_.isEmpty($stateParams.recalculate)){
			vm.recalculate = true;
		}

		if(!_.isEmpty(user.profile)){
			if(!_.isEmpty(user.profile.affordability_categories)){
				if(!_.isEmpty(user.profile.affordability_categories.EA)){
					var cats = user.profile.affordability_categories.EA;
				
					_.each(cats, function(total, category_id){
						vm.totalAddExpenses += total;
					});
				}
				
				if(!_.isEmpty(user.profile.affordability_categories.ER)){
					var cats = user.profile.affordability_categories.ER;
				
					_.each(cats, function(total, category_id){
						vm.totalRemExpenses += total;
					});
				}	
				
				if(!_.isEmpty(user.profile.affordability_categories.E)){
					var cats = user.profile.affordability_categories.E;
				
					_.each(cats, function(total, category_id){
						vm.totalExpenses += total;
					});
				}								
				
				if(!_.isEmpty(user.profile.affordability_categories.I)){
					var cats = user.profile.affordability_categories.I;
				
					_.each(cats, function(total, category_id){
						vm.totalIncome += total;
					});
				}			
				
				if(!_.isEmpty(user.profile.affordability_categories.D)){
					var cats = user.profile.affordability_categories.D;
				
					_.each(cats, function(total, category_id){
						vm.totalDeductions += total;
					});
				}									
				
			}
		}
		
		vm.nett = vm.totalIncome - vm.totalDeductions - vm.totalExpenses - vm.totalAddExpenses + vm.totalRemExpenses;
		
		vm.submit = function(){
			EventFactory.trigger('AffordabilityCompleted');		
			$state.go("private.app.dashboard.modal.certificate.index", {}, {reload: true});
		}		
		
		vm.next = function(){
			$state.go('private.app.dashboard.modal.affordability.failed_affordability');
		}			
	}
})();