(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityFailedAffordabilityController', AffordabilityFailedAffordabilityController);
	
	function AffordabilityFailedAffordabilityController(_, API, $state, user, IntercomFactory, LeadFactory){
		var vm = this;
		angular.element('.modal-dialog').addClass('w-900');
		vm.certificate = function(){
			$state.go('private.app.dashboard.modal.certificate.index');
		};
		vm.submitLead = function(){
			$state.go('private.app.dashboard.modal.verify.lead_submit', {type:"affordability"});
		}
		
		vm.submit = function(){
			$state.go('private.app.dashboard.modal.verify.assistance');
		}
	}
	
})();
