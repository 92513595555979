(function() {
	"use strict";

	angular.module('finlayer').factory('TransActSiteFactory', function(API) {
		var factory = {};
		var prefix = 'finlayer/trans_act/site';
		
		factory.get = function(site_id){			
			return API.one(prefix + '/view/' + site_id).get(); 
		}
	
		return factory;
	});
})();
