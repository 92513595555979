(function() {
	"use strict";

	angular.module('app.controllers').controller('PropertyFailedController', PropertyFailedController);
	
	function PropertyFailedController(_, API, cfpLoadingBar, $state, IntercomFactory, $sce, $window, $timeout, lead){
		var vm = this;
	//	cfpLoadingBar.start();
		vm.submit = submit;
		
    function submit(){
			$state.go("private.app.dashboard", {}, {reload: true});
    }		
	}
	
})();
