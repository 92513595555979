(function() {
	"use strict";

	angular.module('finlayer').factory('ShiftCtrlAssetFactory', function(API) {
		var factory = {};
		var prefix = 'finlayer/shift_ctrl/asset';
		
		factory.getAll = function(){			
			return API.all(prefix).getList();
		}
		
		factory.uploadRawThumbnail = function(description, data){
			return API.all(prefix + '/thumbnail/'+description).post({data: data});
		}
		
		factory.getThumbnail = function(description){
			return API.one(prefix + '/thumbnail/' + description).get();
		}
		
		factory.deleteThumbnail = function(description){
			return API.one(prefix + '/thumbnail/' + description).remove();
		}

		return factory;
	});
})();
