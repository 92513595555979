(function() {
	"use strict";

	angular.module('app.controllers').controller('ContactFormController', ContactFormController);
	
	function ContactFormController(_, user, $state, ManualFactory){
		var vm = this;
		vm.user = user;
		
		vm.submit = function(){
			var data = vm.contact;
			ManualFactory.contact(data)
				.then(function(status){
					if(status == true){
						$state.go('private.app.dashboard.modal.contact.end');
					}else{
						$state.go('private.app.dashboard.modal.contact.error');
					}
				});
		}
		angular.element('.modal-dialog').removeClass('w-900');
	}
})();