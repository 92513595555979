(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityReviewDeductionsController', AffordabilityReviewDeductionsController);
	
	function AffordabilityReviewDeductionsController(_, API, $scope, $state, categories, userProfile, UserFactory, IntercomFactory, $locale){
		var vm = this;
		var user = userProfile;
		//hack
		$locale.NUMBER_FORMATS.CURRENCY_SYM = 'R';
		
		vm.userCategories = {};
		vm.categories = categories;
		vm.total = 0;
		vm.netIncome = 0;
		var totalIncome = 0;
		
		_.each(categories, function(category, i){
			if(category.total){				
				vm.userCategories[category._id] = category.total;				
			}
		});		
		
		if(!_.isEmpty(user.profile)){
			if(!_.isEmpty(user.profile.affordability_categories)){
				if(!_.isEmpty(user.profile.affordability_categories.D)){
					var cats = user.profile.affordability_categories.D;
				
					_.each(cats, function(total, category_id){
						vm.userCategories[category_id] = total;
					});
				}
				
				if(!_.isEmpty(user.profile.affordability_categories.I)){
					var cats = user.profile.affordability_categories.I;
				
					_.each(cats, function(total, category_id){
						totalIncome += total;
					});
				}				
								
			}
		}
		
		vm.submit = function(){
			var data = {};
			_.each(vm.userCategories, function(total, cat){
				if(isNaN(total)){
					total = 0;
				}
				
				data[cat] = total;
			});

			UserFactory.postProfile({"affordability_categories": {"D": data}})
				.then(function(){			
					IntercomFactory.trackEvent('Affordability_Confirmed_Categories_Deductions');				
					$state.go('private.app.dashboard.modal.affordability.review_expenses', {}, {reload: true});
				});
		}
		
		vm.calcTotal = function(){
			var total = 0;
			_.each(vm.userCategories, function(amount, cid){
				if(isNaN(amount)){
					amount = 0;
				}
				total += amount;
			});	
			
			return total;	
		}		
		
		$scope.$watch('vm.userCategories', function(newVal, oldVal){
		    vm.total = vm.calcTotal();
		    vm.netIncome = totalIncome - vm.total;		    
		}, true);	
	}
})();