(function() {
	"use strict";

	angular.module('app.controllers').controller('CheckListController', CheckListController);
	
	function CheckListController(_, user, $timeout, $state, IntercomFactory, UserFactory){
		var vm = this;
		vm.user = user;
		vm.share_consent = true;
		
		vm.option1 = function(){
			
			UserFactory.postProfile({"share_consent": vm.share_consent})
				.then(function(){						
					$state.go('private.app.dashboard.modal.otp.mobile');					
				});			
		}

		angular.element('.modal-dialog').addClass('w-900');
		angular.element('.modal-content').addClass('transparent');
	}
})();