(function() {
	"use strict";

	angular.module('finlayer').factory('TransActBankFactory', function(API) {
		var factory = {};
		var prefix = 'finlayer/trans_act/bank';
		
		factory.getPopular = function(){			
			return API.all(prefix + '/popular').getList();
		}
	
		return factory;
	});
})();
