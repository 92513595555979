(function() {
	"use strict";

	angular.module('app.controllers').controller('AffordabilityStatementTypeController', AffordabilityStatementTypeController);
	
	function AffordabilityStatementTypeController(_, $state, $stateParams, $log, API, TransActAccountFactory, IntercomFactory, banks){
		var vm = this;
		vm.data = {};	
		vm.submit = submit;
		
		angular.element('.modal-dialog').removeClass('w-900');		
		
		vm.banks = _.filter(banks, function(bank){
			return bank.statement == true;
		});		
		
		function submit(){
			$state.go('private.app.dashboard.modal.affordability.statement_upload', {bank_id: vm.data.bank.bank_id});
		}
	}
})();