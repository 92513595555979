//==============================================
// Certificate Service
//==============================================

(function() {
	"use strict";

	angular.module('app.services').factory('CertificateFactory', function($http, API, $localStorage, $auth, $q) {
		var factory = {};
		var prefix = 'certificate';
		
		//Need to find a way to get the raw response using RestAngular
		factory.getHtml = function(certificate_id){

			var headers = {};
			headers.Authorization = 'Bearer ' + $auth.getToken();

			var deferred = $q.defer()
			console.log(certificate_id);
			var resp = $http.get('api/certificate/quote/'+certificate_id, {headers: headers})
				.then(function(response){
					deferred.resolve(response.data);
				}, function(err){
					return deferred.reject(err);
				});
				
			return deferred.promise;
		}
		
		factory.get = function(certificate_id){
			return API.one(prefix+'/view/'+certificate_id).get();
		}
		
		factory.getAll = function(){
			return API.all(prefix).getList();
		}
		
		factory.postInvite = function(certificate_id, data){
			return API.all(prefix + '/invite/'+certificate_id).post(data);
		}
		
		factory.getPdfLink = function(certificate_id){
			return 'api/'+prefix+'/download/'+certificate_id+'/?token='+$auth.getToken();
		}
		
		factory.getClaim = function(partner_code){
			return API.one(prefix+'/claim/'+partner_code).get();
		}
		
		factory.getInvites = function(){
			return API.one(prefix+'/invites').get();
		}		
		
		factory.getCount = function(){
			return API.one(prefix+'/count').get();
		}		
		
		factory.getConciergeCount = function(){
			return API.one(prefix+'/concierge-count').get();
		}			
		return factory;
	});
})();
